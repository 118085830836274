//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Learn How Clear Aligners Work. Try risk free ortho.",
			"DESCRIPTION": "Our Clear Aligners are worth it! Learn how Smiles Club Process works, and how to get your Full Treatment Risk free! High quality USA made non-toxic ortho.",
			"KEYWORDS": "straighten teeth from home, affordable, money back, affirm, stripe, afterpay, pay later, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, financial options, safe plastics, discreetly, clear retainers"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Lets Get Started</div>
						<h1 class="sectionTitle">With Smiles Club getting straight teeth has never been easier!</h1>
						<div class="">It's simple, purchase our "<strong>FULL TREATMENT PACKAGE</strong>", and we'll send you an impression kit plus everything you need in order to get your clear aligners. Then, once we get your oral impressions, we'll create all your clear aligners and ship them to you. That's it!</div>
						<div><br/><strong>NO HIDDEN FEES, NO SURPRISE CHARGES, ONE PACKAGE COVERS EVERYTHING!</strong><br/><br/></div>
						<div class="">And if, for whatever reason, our doctors determine you don't qualify, we will reimburse you 100%! That's our money back guarantee! Read more below.</div>
					`
				}
			],
			"TopMainSectionImage": [{
				"alt": "Women with daughter Looking up financial options for clear aligners on a laptop."
			}],
			"FullSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">FULL PROCESS STEPS</div>
						<h2 class="sectionTitle2">How does it work?</h2>
						<div class="subHeader">These are the steps to a beautiful smile</div>
					`
				}
			],
			"Step1Image": [{
				"alt": "Woman on laptop holding credit card ready to buy clear aligners"
			}],
			"Step1": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 1</div>
								<h4>Purchase your Full Treatment Plan</h4>
								<p>The "Treatment Plan" includes your FREE Impression Kit, your full step by step doctor approved treament plan, and all your aligners from start to finish.</p>
								<p>You will receive the most affordable price in the market. Also, you will have the option to finance the whole treatment. No credit history will be checked. You can get financing regardless of your credit score.</p>`
				}
			],
			"Step2Image": [{
				"alt": "Woman on laptop with glasses researching invisible braces"
			}],
			"Step2": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 2</div>
								<h4>Get Your Impression’s Kit</h4>
								<p>You will get a box containing everything you need to successfully make an impression of your teeth at home. It is an easy process. Watch this video to learn how.</p>
								<p>To get your impression kit fill out the form and contact us. We'll contact you and figure out the best next step for your specific case.</p>`
				}
			],
			"Step3Image": [{
				"alt": "Oral Impression of teeth sitting on a counter."
			}],
			"Step3": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 3</div>
								<h4>Take a few selfies and then take an impression</h4>
								<p>The kit has a pre-paid return label for you to send your impressions. Taking the impressions is easy. Watch this video to learn how.</p>               `
				}
			],
			"Step4Image": [{
				"alt": "Package of clear aligners on the porch."
			}],
			"Step4": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 4</div>
								<h4>Receive your personalized treatment plan.</h4>
								<p>An Orthodontist will design a personalized treatment plan. The plan will be approved by a second, licensed dentist. You will receive the plan, before and after pictures and some videos that will show you how the plan will transform your smile.</p>`
				}
			],
			"Step5Image": [{
				"alt": "Clear braces being held by two fingers in front of a gray background."
			}],
			"Step5": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 5</div>
								<h4>Receive your clear aligners</h4>
								<p>Within two weeks your clear aligners will be fabricated and delivered to your door with clear instructions for wearing and monitoring progress.</p>`
				}
			],
			"Step6Image": [{
				"alt": "A woman wearing Smiles Club's invisible liners smiling in a mirror."
			}],
			"Step6": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Step 6</div>
								<h4>Monitor your progress</h4>
								<p>You will continue the treatment as indicated and report back to our Doctors periodically. Our Doctors will review your progress and make adjustments if necessary, to insure best results.</p>
								<p>At the end of the treatment the use of retainers at nighttime is highly recommended</p>        
							`
				}
			],
			"FinalText": [
				{
					"type": "div",
					"className": "text-center",
					"content": `<div style="margin: auto; max-width: 800px;">
									<br/>
									<h3 class="sectionTitle3" style="max-width: 500px;margin: auto;">Get your WHOLE TREATMENT with <strong>NO HIDDEN FEES TODAY!</strong></h3>
								<br/>
								<p>Pay upfront using your FSA or HSA card, your bank account, credit cards, or other simply pay with Affirm, Paylater, Amazon Pay, and more. <strong>WE EVEN HAVE CLEAR ALIGNER FINANCING WITH AFFIRM FLEXIBLE PAYMENT OPTIONS UP TO 36 MONTHS!</strong> Lock this amazing deal today and save more by paying all at once! Contact us if you have any questions or for more details.</p>
								<br/>
							</div>
							`
				}
			],
			"FinalTextButton": [
				{
					"content": `Buy Now`
				},
				{
					"content": `#BuyNowProduct"`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Aprenda cómo funcionan los alineadores transparentes.",
			"DESCRIPTION": "¡Nuestros alineadores transparentes valen la pena! ¡Conozca cómo funciona el Proceso Smiles Club y cómo obtener su Tratamiento Completo sin Riesgos! Hecho en los EE. UU. Alineadores no tóxicos de alta calidad.",
			"KEYWORDS": "Sonrisas Club, Sonrisas frenos, enderezar los dientes desde casa, asequible, devolución de dinero, Affirm, Amazon Pay, Google Pay, Paylater, pague después, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, opciones financieras, plásticos seguros, discretamente, retenedores transparentes"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Comencemos</div>
						<h1 class="sectionTitle">¡Con Smiles Club conseguir dientes rectos nunca ha sido tan fácil!</h1>
						<div class="">Es simple, compra nuestro "<strong>PAQUETE DE TRATAMIENTO COMPLETO</strong>" y te enviaremos un kit de impresión más todo lo que necesitas para obtener tus alineadores transparentes. Luego, una vez que tengamos sus impresiones orales, crearemos todos sus alineadores transparentes y se los enviaremos. ¡Eso es todo!</div>
						<div><br/><strong>¡SIN CARGOS OCULTOS NI CARGOS SORPRESA, UN PAQUETE CUBRE TODO!</strong><br/><br/></div>
						<div class="">Y si, por cualquier motivo, nuestros médicos determinan que usted no califica, ¡le reembolsaremos el 100%! ¡Esa es nuestra garantía de devolución de dinero! Lea más a continuación.</div>
					`
				}
			],
			"TopMainSectionImage": [{
				"alt": "Mujeres con hija buscando opciones financieras para alineadores transparentes en una computadora portátil."
			}],
			"FullSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Pasos del Proceso</div>
						<h2 class="sectionTitle2">¿Cual es el proceso?</h2>
						<div class="subHeader">Estos son los pasos para una sonrisa hermosa</div>
					`
				}
			],
			"Step1Image": [{
				"alt": "Mujer en una laptop con tarjeta de crédito lista para comprar alineadores transparente."
			}],
			"Step1": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 1</div>
							<h4>Compra tu Plan de Tratamiento Completo</h4>
							<p>El "Plan de tratamiento" incluye tu kit de impresión GRATUITO, tu plan de tratamiento completo paso a paso aprobado por el médico y todos tus alineadores de principio a fin.</p>
							<p>Recibirás el mejor precio del mercado. Podrás pagarlo al contado o en cuotas. No revisaremos tu crédito. Podrás recibir financiación sin importar tu historia de crédito.</p>`
				}
			],
			"Step2Image": [{
				"alt": "Mujer en una laptop con anteojos investigando aparatos ortopédicos invisible."
			}],
			"Step2": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 2</div>
							<h4>Ordena tu kit de impresión</h4>
							<p>Recibirás una caja con todo lo necesario para realizar una impression de tus dientes. Es muy facil, mira este video para saber como hacerlo.</p>
							<p>Para obtener tu kit de impresión llene el formulario y contáctenos. Nos pondremos en contacto con usted y determinaremos el mejor paso a seguir para tu caso específico.</p>`
				}
			],
			"Step3Image": [{
				"alt": "Impresión oral de dientes sentados en un mostrador."
			}],
			"Step3": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 3</div>
							<h4>Realiza la impresió y envíanosla</h4>
							<p>El kit tiene un sobre pre-pagado para que nos envies la impression. Tomar impresiones es facil, mira este video para saber como hacerlo.</p>  `
				}
			],
			"Step4Image": [{
				"alt": "Paquete de alineadores transparentes en el porche."
			}],
			"Step4": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 4</div>
							<h4>Recibe tu tratamiento personalizado</h4>
							<p>Un Ortodoncista diseñará tu tratamiento y otro Dentista licenciado en los Estados Unidos revisará el plan. Tu recibirás el plan y videos para visualizar como será el tratamiento y como quedará tu sonrisa.</p>`
				}
			],
			"Step5Image": [{
				"alt": "Abrazaderas transparentes sujetadas con dos dedos frente a un fondo gris."
			}],
			"Step5": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 5</div>
							<h4>Recibe tus alineadores transparentes</h4>
							<p>En el transcurso de dos semanas fabricaremos y te enviaremos los alineadores a tu domicilio con instrucciones de como usarlos y monitorear tu progreso.</p>`
				}
			],
			"Step6Image": [{
				"alt": "Una mujer con los protectores invisibles de Sonrisas Club sonriendo en un espejo."
			}],
			"Step6": [
				{
					"type": "div",
					"content": `<div class="preHeader2">Paso 6</div>
							<h4>Monitorea tu progreso</h4>
							<p>Continuarás el tratamiento como se te ha indicado y te contactaras con nuestros Dentistas periódicamente. Nuestros Dentistas evaluarán tu progreso y harán ajustes de ser necesario.</p>
							<p>Al final del tratamiento recomendamos que uses alineadores por las noches mientras duermas.</p>`
				}
			],
			"FinalText": [
				{
					"type": "div",
					"className": "text-center",
					"content": `<div style="margin: auto; max-width: 800px;">
								<br/>
								<h3 class="sectionTitle3" style="max-width: 500px;margin: auto;">¡Obtenga su TRATAMIENTO COMPLETO SIN CARGOS OCULTOS HOY!</h3>
								<br/>
								<p>Pague por adelantado con su tarjeta FSA o HSA, su cuenta bancaria, tarjetas de credito u otra forma de pago simple con Amazon Pay, Google Pay, o Cash App. <strong>¡INCLUSO TENEMOS FINANCIAMIENTO CLEAR ALIGNER CON AFFIRM!</strong> ¡Obtenga esta increíble oferta hoy y ahorre más pagando todo de una vez! Póngase en contacto con nosotros si tiene alguna pregunta o para obtener más detalles.</p>
								<br/>
							</div>
							`
				}
			],
			"FinalTextButton": [
				{
					"content": `Compra`
				},
				{
					"content": `#BuyNowProduct"`
				}
			],
		}
	}
};


	
export default PAGE;
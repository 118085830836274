import React from 'react';
import FormBuilder from 'global/components/FormBuilder/index';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const REQUIREDMESSAGE = ()=>{
  return(
    <span style={{"color":"red","fontSize":"9px","paddingLeft":"10px","paddingTop":"6px"}}><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}  /> <span style={{"paddingLeft":"4px"}}>{window.Session.Language === "ENGLISH" ? "REQUIRED" : "REQUERIDA"}</span></span>
  )
}
const ERRORMESSAGE = (customMessage="This field is required before submitting")=>{
  return(
    <div style={{"color":"red","fontSize":"12px","paddingLeft":"10px","paddingTop":"6px"}}>{customMessage}</div>
  )
}

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.currentObject = {
      ...(this.props.initialValues ? this.props.initialValues : {})
    };
  }

  componentDidMount(){
  }

  componentWillUnmount(){
  }

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);      
    var {initialValues, phoneNumber} = this.props;
    const STATES = window.GlobalUtil.states();
    //var {data} = this.props;
    var ListOfFields = [
      {
        title: <div className="formTitle"><span className="">{CONTENT[0]}</span></div>,
        type: "text",
        name: "street",
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[1])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[2]}</span></div>,
        type: "text",
        name: "street2",
        //placeholder: "",
        required: false,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[3])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[4]}</span></div>,
        type: "text",
        name: "city",
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[5])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[6]}</span></div>,
        type: "select",
        name: "state",
        options: Object.keys(STATES).map(stateKey=>{return({title: STATES[stateKey], value: STATES[stateKey]})}),
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[7])),
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
        subTitle: <small className="">{CONTENT[9]}</small>,
        type: "customText",
        name: "country",
        //placeholder: "",
        text: "USA",
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[10]}</span></div>,
        type: "zipcode",
        name: "zipcode",
        //placeholder: "",
        required: true,
        requiredMessage: (REQUIREDMESSAGE()),
        errorMessage: (ERRORMESSAGE(CONTENT[11])),
      }
    ]
    if(phoneNumber) ListOfFields.push({
      title: <div className="formTitle"><span className="">{CONTENT[12]}</span></div>,
      type: "phone",
      name: "phone",
      //placeholder: "",
      required: true,
      requiredMessage: (REQUIREDMESSAGE()),
      errorMessage: (ERRORMESSAGE(CONTENT[13])),
    })
    return(
      <div className="componentShippingForm">
        <FormBuilder
          initialValues={initialValues}
          listOfFields={ListOfFields}
          onChange={newObj =>{
            this.currentObject = newObj;
            if(this.props.onChange) this.props.onChange(newObj);
          }}
          onValidate={(isValid)=>{
            this.setState({isValid: isValid});
            if(this.props.onValidate) this.props.onValidate(isValid);
          }}
        />
      </div>
    )
  }
}



export default Component;
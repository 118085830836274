import React from 'react';
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curNumber: 0
    };  
    this.startCounting = this.startCounting.bind(this);
  }

  componentDidMount(){
    //setTimeout(()=>{        
      this.startCounting();
    //}, 1000);
  }

  componentWillUnmount(){
    clearTimeout(this.CountingRef)
  }

  startCounting(){
    var {countBy=20} = this.props;
    var curNumber = Number(this.state.curNumber);
    if(curNumber >= this.props.countTo) return;
    else {
      this.CountingRef = setTimeout(()=>{
        var newNumber = curNumber+countBy;
        if(newNumber > this.props.countTo) newNumber = this.props.countTo;
        if(this){
          this.setState({curNumber: newNumber});
          this.startCounting();
        }
      }, 10);
    }
  }

  render(){
    var {curNumber} = this.state;
    var {maxSize, type, typeName, upto, uptoText, className} = this.props;          
    //if(Number(curNumber) >= this.props.countTo) sizeBarStyles.minWidth = '80px';
    if(className === "price") return (
      <span className={className}>${window.GlobalUtil.numberWithCommas(curNumber, 0)}</span>
    );

    return (
      <span className={className}>{window.GlobalUtil.numberWithCommas(curNumber, 0)}</span>
    );
  }
}


export default Index;
      
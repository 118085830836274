import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import API_CALLS from 'API_CALLS/index';
import { gql, useQuery, useMutation } from '@apollo/client';
import FormBuilder from 'global/components/FormBuilder/index';
import Loading from 'global/components/Loading/index';
import ESPAÑOL from "./ESPANOL";
import ENGLISH from "./ENGLISH";
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}


const REQUIREDMESSAGE = (message)=>{
  return(
    <span className="REQUIREDMESSAGE"><i className="fas fa-star-of-life" style={{"fontSize":"7px"}}  /> <span style={{"paddingLeft":"4px"}}>{message}</span></span>
  )
}
const ERRORMESSAGE = (customMessage)=>{
  return(
    <div className="ERRORMESSAGE" style={{"color":"red","fontSize":"12px","paddingLeft":"10px","paddingTop":"6px"}}>{customMessage}</div>
  )
}
class ContactFormSmall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false
    };  
    this.currentObject = {
      GID: this.props.GID,
      language: window.Session.Language
    };
  }

  componentDidMount(){
    //setTimeout(()=>{        
    //}, 1000);
  }

  componentWillUnmount(){
  }

  onRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    this.setState({captchaToken: value})
  };


  APICheckEmailWorks = () => {
    var cleanObj = {email: this.currentObject.email, GID: this.currentObject.GID};             
    return window.Client.mutate({
      mutation: API_CALLS.GIVEAWAY_CONTS.QUERIES.emailChecker(),
      variables: cleanObj
    })
    .then(result => {
      console.log(`result`,"\n\n",result,"\n\n");
      if(result.data.giveawayContEmailChecker) return true;
      return false;
    })
    .catch((error)=>{
      console.log("API_CALLS.GIVEAWAY_CONTS.QUERIES.emailChecker FAIL catch", error);
    })
  }

  onSubmitContact = async () => {        
    const recaptchaValue = this._reCaptchaRef.getValue();
    if(!recaptchaValue){
      //alert("Please click the ReCAPTCHA at bottom of form.");
      return;
    } 


    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]);  
    var {isValid} = this.state;
    if(!isValid) return;
    var ComObj = {
      ...this.currentObject
    }
    if(!ComObj.email || !ComObj.GID) return;
    //console.log("this.currentObject");
    //console.log(this.currentObject,"\n\n");  
    this.setState({loading: true, isValid: false});
    //UPPERCASE ANY VALUES
    /*
      GID
      name
      email
      phone
    */

    var emailHasBeenUsed = await this.APICheckEmailWorks();
    if(emailHasBeenUsed) {
      this.setState({ 
        submitError: false, 
        show: "success",
        loading: false
      }, ()=>{
        window.GlobalUtil.triggerEvent("ShowConfetti", true);
      });
      return;
    }
          
    window.Client.mutate({
      mutation: API_CALLS.GIVEAWAY_CONTS.MUTATIONS.create(),
      variables: ComObj
    })
    .then(result => {
      var data = window.GlobalUtil.deepGetFromString(result, "data", {});
      //console.log("data");
      //console.log(data,"\n\n"); 
      this.setState({ 
        submitError: false, 
        show: "success",
        loading: false
      }, ()=>{
        window.GlobalUtil.triggerEvent("ShowConfetti", true);
      });
    })
    .catch((error)=>{
      window.GlobalUtil.consoleLogNew({LOCATION:`src/global/components/JoinGiveawayForm/index.js onSubmitContact()`, NAME:"error", CONTENT:[error]});
      if(error.message === 'Unexpected error value: "Error: EMAIL EXIST"'){
        console.log(`EMAIL EXIST ERROR`,"\n\n","\n\n");
        this.setState({ 
          submitError: error.message, 
          loading: false, 
          show: "EMAIL_EXIST" 
        });
      } else {
        this.setState({ 
          submitError: error.message, 
          loading: false, 
          show: "fail" 
        });
      }
    });
  }
 

  render(){
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    var {isValid, show, loading, captchaToken} = this.state;
    var {maxSize, type, typeName, upto, successMessage} = this.props;
    if(!window.Client) return null;
    if(loading){
      return (
        <div className='c2r2div4'>
          <Loading 
            header={CONTENT[0]}
            body={CONTENT[1]}
            inlineStyles={{
              "loadingIcon": {
                "maxWidth": "100px",
                "paddingBottom": "20px"
              },
              "loadingHeader": {
                "marginBottom": "5px",
                "fontSize": "40px"
              },
              "loadingBody": {
                "marginBottom": "0px",
                "fontSize": "20px"
              }
            }}
            floating={false}
            overlay={true}
          />
        </div>
      )
    }


    if(show){
      return <div className='submitMessageWrapper c2r2div4'>
        {
          (show === "success") &&
          successMessage
          ? successMessage
          : <div id="SuccessMessage" className="animated fadeIn message">
              <div className="messageText">
                <div>
                  <h1><i className="fas fa-laugh-beam"></i></h1>
                  <h2>
                    {CONTENT[2]}
                  </h2>
                  <div className="text">
                    {CONTENT[3]}
                  </div>
                </div>
              </div>
            </div>
           
        }
        {
          (show === "fail") && 
          <div id="SuccessMessage" className="animated fadeIn message">
            <div className="messageText">
              <div>
                <h1>
                  {CONTENT[4]}
                </h1>
                <h2>
                  {CONTENT[5]}
                </h2>
                <div className="text">
                  {CONTENT[6]}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    }
    return (
      <React.Fragment>
        <div className="c2r2div3">
          <div className="c2r2div3Row1">
            <div className={`smallForm`}>
              <FormBuilder
                initialValues={this.currentObject}
                listOfFields={[
                  {
                    title: <div className="formTitle"><span className="">{CONTENT[7]}</span></div>,
                    type: "text",
                    name: "name",
                    subTitle:'',
                    //placeholder: "",
                    required: true,
                    requiredMessage: REQUIREDMESSAGE(CONTENT[13]), //
                    errorMessage: ERRORMESSAGE(CONTENT[14]),
                  },
                  {
                    title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
                    type: "phone",
                    name: "phone",
                    subTitle:'',
                    //placeholder: "",
                    required: true,
                    requiredMessage: REQUIREDMESSAGE(CONTENT[13]),
                    errorMessage: ERRORMESSAGE(CONTENT[14]),
                  },
                  {
                    title: <div className="formTitle"><span className="">Email</span></div>,
                    type: "email",
                    name: "email",
                    subTitle:'',
                    //placeholder: "",
                    required: true,
                    id: "JoinGiveawayEmail",
                    requiredMessage: REQUIREDMESSAGE(CONTENT[13]),
                    errorMessage: ERRORMESSAGE(CONTENT[14]),
                  },
                  {
                    title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
                    subTitle: <div className="formSubTitle"><span className="">{CONTENT[12]}</span></div>,
                    type: "select",
                    name: "language",
                    options: [
                      {
                        "value": "",
                        "title": "Choose one",
                        "disabled": true
                      },
                      {
                        "value": "ENGLISH",
                        "title": "ENGLISH"
                      },
                      {
                        "value": "ESPAÑOL",
                        "title": "ESPAÑOL"
                      }
                    ],
                    required: true,
                    requiredMessage: REQUIREDMESSAGE(CONTENT[13]),
                    errorMessage: ERRORMESSAGE(CONTENT[14]),
                  }
                ]}
                onChange={newObj => {
                  // if(newObj.name && newObj.phone && !this.FormStarted){
                  //   this.FormStarted = true;
                  //   if(!window.Session || !window.Session.user || !window.Session.user._id) APISaveForTracking({"type":"FORM_STARTED", page: "HOME"});
                  // }
                  this.currentObject = newObj;
                        
                }}
                onValidate={(isValid)=>{this.setState({isValid: isValid})}}
              />
              <div style={{"margin": "20px 0 0","width": "100%"}}>
                <ReCAPTCHA
                  ref={e=>this._reCaptchaRef=e}
                  sitekey={"6LcKy6kdAAAAAPuG69C6Fa4UppRoN9I1DwyKGll5"}//process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={this.onRecaptchaChange}
                />
              </div>
              <div className="buttonWrapper">
                <button disabled={(!isValid || !captchaToken) ? true : false} className={`button button1 button-accept ${(!isValid || !captchaToken) ? "disabled" : ""}`} onClick={this.onSubmitContact}>{CONTENT[15]}</button>
              </div>
            </div>
          </div>
        </div>  
      </React.Fragment>
    );
  }
}



export default ContactFormSmall;
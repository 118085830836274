import React from 'react';
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import {NavLink} from 'react-router-dom';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categories: null
    };    
  }
  
  componentDidMount(){
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getCategories);
    this.getCategories();
  }

  componentWillUnmount(){
    this.RunAPIOnceWhenReady.unsubscribe();
    this.LoadingAPI = false;
  }

  getCategories = async () => {
    //console.log('getCategories');
    if(!window.Client || !window.Client.query || this.LoadingAPI) return false;
    this.setState({loading: true});
    //console.log("variables","\n\n",variables,"\n\n");
    this.LoadingAPI = true;
    return await window.Client.query({
      query: API_CALLS.BLOGS.QUERIES.distinct(),
      variables: {
        field: "category", //FIELD TO GET ALL OPTIONS
        otherParams: JSON.stringify({"duration.startAt": {"$lte": Date.now()}})
      },
      fetchPolicy: "cache"
    })
    .then(result => {
      this.LoadingAPI = false;
      this.setState({
        loading: false,
        categories: result.data.blogDistinct
      });
    })
    .catch(error => {
      this.LoadingAPI = false;
      console.log('error', error);
      this.setState({
        loading: false
      });
    });
  }



  render(){
    var {loading, categories} = this.state;
    var {categoryActive, onChangeCategory=()=>{}} = this.props;
    //console.log('categoryActive', categoryActive);
    
    return (
      <div id="Categories" className="sideMenuItem">
        <div className="sectionMenuTitle">Categories</div>
        <ul className="categoriesUl">
          {
            (categories && categories.length)
            ? <React.Fragment>
                <li>
                  {
                    this.props.withNav
                    ? <NavLink 
                        className={({ isActive, isPending }) =>{
                          var classString = "categoryFilterLink";
                          //if(isPending) classString += " pending";
                          //if(isActive) classString += " active";
                          if(!categoryActive) classString += " active";
                          return classString;
                        }}
                        onClick={()=>onChangeCategory()}
                        to={`/blog`}>ALL</NavLink>
                    : <a className={`categoryFilterLink ${!categoryActive ? "active" : ""}`}  onClick={()=>onChangeCategory()}>ALL</a>
                  }
                  
                </li>
                {
                  categories.map((categoryLink, index)=>{
                    return(
                      <li key={index}>
                        {
                          this.props.withNav
                          ? <NavLink 
                            className={({ isActive, isPending }) =>{
                              var classString = "categoryFilterLink";
                              //if(isPending) classString += " pending";
                              //if(isActive) classString += " active";
                              if(categoryActive && (categoryActive === categoryLink)) classString += " active";
                              return classString;
                            }}  
                            to={`/blog?CATEGORY=${categoryLink}`} onClick={()=>onChangeCategory(categoryLink)}>{categoryLink}</NavLink>
                          : <a className={`categoryFilterLink ${(categoryActive && (categoryActive === categoryLink)) ? "active" : ""}`}  onClick={()=>onChangeCategory(categoryLink)}>{categoryLink}</a>
                        }
                      </li>
                    )
                  })
                }
              </React.Fragment>
            : loading
              ? <Loading 
                  floating={false}
                  header=""
                  body=""
                  inlineStyles={{
                    "loadingIcon": {
                      "width": "40px",
                      "height": "40px",
                      "margin": "0px",
                      "marginLeft": "20px",
                    },
                    "loadingHeader": {
                      "marginBottom": "5px",
                      "fontSize": "40px"
                    },
                    "loadingBody": {
                      "marginBottom": "0px",
                      "fontSize": "20px"
                    }
                  }}
                />
              : <p className="">Failed to Load</p>
          }
        </ul>
      </div>
    );
  }
}






export default Index;
      
//import React from 'react';
const PAGE = [
  {
    "content": `Official Rules`
    //CONTENT 29
  },
  {
    "type": "div",
    "content": `
    <div class="basicText">Effective May 1, 2022.</div></br>

    <div class="basicText"><strong class="emph2">NOTICE REGARDING DISPUTE RESOLUTION:</strong> These official rules contain terms that govern how claims between you and Company, relating to your participation in any drawing, will be resolved. For example, section 17 includes an arbitration agreement and waiver of a class action that requires you to submit all claims to arbitration (instead of going to court before a judge and jury) and limits your ability to bring claims on behalf of anyone other than yourself (that is, it prohibits you from bringing any purported class action or purported collective or representative proceeding).</div></br>

    <div class="basicText">No purchase, or payment of any kind is necessary to enter or win. All entries into a drawing have an equal chance of winning. A purchase, or payment will not increase the chances of winning.</div></br>

    <div id="RulesSection1" class="basicText"><strong class="emph2">1. THE COMPANY:</strong> <strong class="emph2">Sonrisas, LLC.</strong>  (referred to herein as “Smiles Club” or “Company”) is a United States, Limited Liability and a for-profit company.  </div></br>

    <div id="RulesSection2" class="basicText"><strong class="emph2">2. The Website:</strong> The Smiles Club' website (<a href="/" target="_blank">www.Smiles.club</a>) (the “Website”) allows eligible individuals (see restrictions below) to enter into the drawing promotions hosted on the Website (each a “Drawing”). </div></br>

    <div id="RulesSection3" class="basicText"><strong class="emph2">3. Applicability of the Rules:</strong> These Official Rules provide the general terms and conditions applicable to every Drawing on the Website launched on or after May 1, 2022. Each Drawing is subject to all applicable international, federal, state, and local laws and regulations and is void where prohibited. </div></br>

    <div class="basicText">
      <div id="RulesSection3A" class="basicText"><strong class="emph2">(A)</strong> Binding Contract. The Official Rules (including any drawing Rules) form a binding contract between Company and each Drawing participant (a “Participant.”)  Entry into any drawing constitutes Participant’s full and unconditional agreement to the Official Rules and Company’s decisions, which are final and binding in all matters relating to the Website and all Drawings. If you do not agree to these Official Rules (including the applicable Drawing Rules) do not enter the Drawing(s).</div></br>

      <div id="RulesSection3B" class="basicText"><strong class="emph2">(B)</strong> Updates to the Official Rules. These Official Rules may be updated from time to time. Please check these Official Rules regularly to ensure that you understand the terms that apply at the time that you enter the Drawing. </div></br>
    </div>

    <div id="RulesSection4" class="basicText"><strong class="emph2">4. ELIGIBILITY:</strong> To be eligible to enter a drawing or be awarded the prizes(s) identified on each Drawing’s webpage (each a “Prize”), a Participant must fully comply with the Official Rules. Unless otherwise provided in the Drawing Rules, Drawings are only open to individuals who <strong class="emph2">(i)</strong> are at least 18 years of age or older at time of entry; <strong class="emph2">(ii)</strong> are NOT in one or more of the “Prohibited Persons” categories listed in <span class="highlightTrigger" data-highlight="#RulesSection4A">Section <strong class="emph2">4(A)</strong></span>, below; and <strong class="emph2">(iii)</strong> are legal residents of the United States or another country that is NOT listed as a “Prohibited Jurisdiction,” in <span class="highlightTrigger" data-highlight="#RulesSection4B">Section <strong class="emph2">4(B)</strong></span>, below. Any entry submitted by any ineligible person(s) will be void.
      </br>
      </br>
      <div id="RulesSection4A" class="basicText"><strong class="emph2">(A)</strong> Prohibited Persons. The following individuals are not eligible to enter or win any drawing:</br></br>

        <div class="basicText"><strong class="emph2">(i)</strong> Any individual who has won a grand-Prize in a Grand-Prize Drawing (as defined in <span class="highlightTrigger" data-highlight="#RulesSection5A">Section <strong class="emph2">5(A)</strong></span>), below) in the immediately preceding 18 months (calculated from the last date of the Entry Period of the last Grand-Prize Drawing that such individual won);</div></br>

        <div class="basicText"><strong class="emph2">(ii)</strong> Any employees, officers, and/or directors of any of the following (collectively, the “Drawing Partners” ): <strong class="emph2">(a)</strong> Smiles Club;  <strong class="emph2">(b)</strong> Smiles Club' third-party contractors who participate in or provide services related to the Winner selection process for the applicable Drawing, and (d) any contractor or other partner engaged by Smiles Club to supply and/or fulfill any portion of the Prize(s), including, without limitation, any Prize supply partners.</div></br>

        <div class="basicText"><strong class="emph2">(iii)</strong> The immediate family members of (meaning, spouse, parent, child, sibling, and their respective spouses, regardless of where they reside) and/or those living in the same household as the employees, officers and/or directors of Smiles Club (whether or not related). </div></br>
      </div>

      <div id="RulesSection4B" class="basicText"><strong class="emph2">(B)</strong> Prohibited Jurisdictions: All countries other than the United State of America.</div></br>
    </div>

    <div id="RulesSection5" class="basicText"><strong class="emph2">5. HOW TO ENTER:</strong> Each Drawing will have a starting date and ending date (the “Entry Period”) specified in the Drawing Rules. Only those entries received by Company during the Entry Period will be valid. Company will be the official timekeeper for all Drawings.
      </br>
      </br>
      <div id="RulesSection5A" class="basicText"><strong class="emph2">(A)</strong> Grand-Prizes Drawings. All grand-Prizes Drawings (each a “Grand-Prize Drawing”) will provide the following way to enter:
        </br>
        </br>
        <div class="basicText"><strong class="emph2">(i)</strong> Enter for Free. To enter for free, visit the Website, click the “Enter Now” button from the Drawing webpage and then submit the completed form. </div></br>
      </div>

      <div id="RulesSection5B" class="basicText"><strong class="emph2">(B)</strong> Secondary Promotions. From time to time, Company may, in its sole and absolute discretion, host other, secondary Drawings on the Website (each a “Secondary Promotion”). Secondary Promotions are those Drawings where the only method of entry is through entry into a specific Grand-Prize Drawing, within a specific timeframe.  Participants who enter the specific Grand-Prize Drawing during the specific timeframe will also be automatically entered into the Secondary Promotion. </div></br>

      <div id="RulesSection5C" class="basicText">
        <strong class="emph2">(C)</strong> Special Promotions. Smiles Club may, in its discretion, run various special promotions in connection with the Drawings. For example, it may, for a temporary period during the Entry Period, offer Participants “bonus entries” in connection with any entry submission, or it may offer Participants the opportunity to enter multiple Grand-Prize Promotions simultaneously. Each entry that is awarded in connection with any special promotion 
          <strong class="emph2">(a)</strong> will constitute a single, independent entry in the applicable Drawing(s), 
          <strong class="emph2">(b)</strong> will be treated the same as any other entry to the Drawing(s) (including being subject to any applicable Entry Limit), and 
          <strong class="emph2">(c)</strong> will have the same chance of winning as any other entry. </div></br>
    </div>

    <div id="RulesSection6" class="basicText"><strong class="emph2">6. ENTRY LIMITATIONS AND RESTRICTIONS:</strong> Only accurate and complete entries submitted during the Entry Period and received by Company are eligible. Any incomplete, illegible, corrupted, or untimely entries are also void and ineligible to win. Proof of submission will not be deemed to be proof of receipt by Company. Company is not responsible for lost, late, illegible, incomplete, invalid, unintelligible, misdirected, technically corrupted or garbled entries, or for other submission problems of any kind, whether caused mechanical, human or electronic error, all of which are void and ineligible to win. Additionally, Company is not responsible for any telephone, electronic, hardware, software, network, Internet, or computer malfunctions, failures, or difficulties.
      </br></br>
      <div id="RulesSection6A" class="basicText"><strong class="emph2">(A)</strong> Entry Limit. For each Drawing, the Drawing Rules will state the cumulative maximum number of entries that any one person may obtain (the “Entry Limit”).  The Entry Limit applies to all entries. If a Participant exceeds the Entry Limit for a drawing, all Entries received over the Entry Limit (in order of receipt) will be void and ineligible to win. The default Entry Limit, unless otherwise specified is one entry per family hold.</div></br>

      <div id="RulesSection6B" class="basicText"><strong class="emph2">(B)</strong> Required Information.  Participants must provide all required information specified on the Drawing’s page or entry form to be eligible to enter and win. </div></br>

      <div id="RulesSection6C" class="basicText"><strong class="emph2">(C)</strong> Email Address Requirements. Participants may not use multiple email addresses, identities, address, logins, devices, or any other tactics used in an effort to receive entries over a drawing’s Entry Limit.  To receive valid entries, Participant must provide a valid email address for which the Participant is the authorized owner. Multiple Participants may not share the same email address.  In the event of a dispute as to the identity of any Participant, the authorized account holder of the email address used to enter will be deemed to be the Participant. The “authorized account holder” is the natural person assigned an email address by an internet access provider, online service provider or other organization responsible for assigning email addresses for the domain associated with the submitted address. Potential Winners and/or Winners (as defined below) may be required to show proof of being the authorized account holder. Any entries connected to an invalid, non-working, or inactive email address, or linked to an authorized account holder other than Participant, are void and ineligible to win. Any conduct in violation of this Section is strictly prohibited and will void all entries for that Participant and may result in disqualification of Participant from all Drawings, at Company’s discretion. </div></br>

      <div id="RulesSection6D" class="basicText"><strong class="emph2">(D)</strong> No automated entry devices. All entries must be submitted by the individual Participant to be valid.  Use, or attempted use, of any robotic, automated, programmed, mechanical, or other non-manual entry methods will void all entries for that Participant and will result in disqualification of the Participant from all Drawings, at Company’s discretion. No copies, facsimiles, or other mechanical or electronic reproductions of entries will be accepted. Company has the sole right and discretion to decide that Participant used or attempted to use any such prohibited automated entry methods.</div></br>

      <div id="RulesSection6E" class="basicText"><strong class="emph2">(E)</strong> Contact Details.  By entering any drawing, you agree that Smiles Club may contact you via email, phone, and mail in connection with the Drawings in accordance with Company’s Privacy Policy, available at: <a href="/privacy" target="_blank">https://www.Smiles.club/privacy</a>.</div></br>
    </div>

    <div id="RulesSection7" class="basicText"><strong class="emph2">7. ENTRY CONDITIONS AND RELEASE:</strong> As a condition of entering any drawing, Participant expressly:
      </br></br>
      <div id="RulesSection7A" class="basicText"><strong class="emph2">(A)</strong> Consents to receive information or promotional materials from Company, or any of the other Drawing Partners, via email and/or mail; </div></br>

      <div id="RulesSection7B" class="basicText"><strong class="emph2">(B)</strong> Represents and warrants that (s)he agrees to be bound by these Official Rules and decisions of the Company, which will be binding and final in all matters relating to each Drawing; </div></br>

      <div id="RulesSection7C" class="basicText"><strong class="emph2">(C)</strong> Releases the Drawing Partners from all liability, loss or damage or expense arising out of or in connection with participation in any drawing or the acceptance, use, or misuse of any Prizes;</div></br>

      <div id="RulesSection7D" class="basicText"><strong class="emph2">(D)</strong> Knowingly and expressly waives all rights to claim, punitive, exemplary, indirect incidental, consequential, or any other damages, other than for actual out-of-pocket expenses and/or any rights to have damages multiplied or otherwise increased;</div></br>

      <div id="RulesSection7E" class="basicText"><strong class="emph2">(E)</strong> Agrees that all claims, disputes, or causes of action arising out of or connected with any drawing, or any Prizes awarded, shall be individually resolved in binding arbitration, per <span class="highlightTrigger" data-highlight="#RulesSection17">Section 17</span>, without resort to any form of class, collective or representative action;</div></br>

      <div id="RulesSection7F" class="basicText"><strong class="emph2">(F)</strong> Agrees that any and all claims, judgments, and awards, if permitted to proceed, will be limited to Participant’s actual out-of-pocket costs incurred (if any), excluding attorneys’ fees and court costs. </div></br>
    </div>

    <div class="basicText">Some jurisdictions do not allow the limitations or exclusion of liability set forth above in <span class="highlightTrigger" data-highlight="#RulesSection7">Section 7</span>. So, some of the provisions contained in this section may not apply to every Participant.</div></br>

    <div id="RulesSection8" class="basicText"><strong class="emph2">8. WINNER DRAWINGS:</strong> Potential winner(s) of a drawing (the “Potential Winner”) will be randomly drawn from among all eligible entries received. Drawings will only occur if a minimum of one hundred valid, legitimate entries have been received for the specific Entry Period specified in the Drawing or if less than one hundred entries, at Company’s sole discretion. The drawing will occur on or after the end of the Entry Period or as specified in the applicable Drawing-specific rules. Company will conduct the drawing and awarding of the Prize. Once confirmed and verified as eligible to win, a Potential Winner is referred to herein as a “Winner.” The odds of winning depend on the number of eligible entries received during the Entry Period.</div></br>

    <div id="RulesSection9" class="basicText"><strong class="emph2">9. WINNER NOTIFICATION PROCESS:</strong> Potential Winners will be contacted using the email address used to enter the Drawing. Potential Winners must respond to the Potential Winner notification email within 24 hours of it being sent (or the time frame indicated in the Potential Winner notification email, if another time frame other than 24 hours is specified). If a Potential Winner fails to respond to the notification email within the stated period, Potential Winner may be disqualified and, in such instances, will forfeit any claim to any of the Prizes(s).  After sending the Potential Winner notification email, to the extent a phone number has been provided, Company reserves the right to also contact Potential Winners via phone as well.  By entering, Potential Winners expressly authorize Company to name, tag, or otherwise identify Potential Winner on Company’s Website, social media pages, and/or in Company’s other promotional materials. </div></br>

    <div class="basicText">Some jurisdictions may prohibit Company from conditioning participation on Potential Winner’s consent to publicity described in this Section. Potential Winners who are residents of such jurisdictions and who object to such publicity, must notify Company of objections via email during the Potential Winner confirmation and verification process.</div></br>

    <div id="RulesSection10" class="basicText"><strong class="emph2">10. CONFIRMATION AND VERIFICATION OF POTENTIAL WINNERS:</strong> Potential Winner will only be confirmed as the winner eligible to receive the Prize after Company has completed verification of Potential Winner’s eligibility to win and confirmed Potential Winner’s compliance with the terms of these Official Rules. Potential Winners must be willing and able to claim and/or accept the Prizes(s) in full in the period specified by Company. Potential Winners are subject to confirmation and verification by Company, by whatever means Company shall deem appropriate. To be confirmed and verified as eligible to win, a Potential Winner and the Potential Winner’s guest(s), if applicable, must comply with all terms and conditions of these Official Rules and fulfill all requirements. Company’s decisions are final and binding in all matters related to the administration, operation, drawing, verification of the Winner, and all issues related to a drawing. In addition to all the other requirements stated in these Official Rules.</div></br>

    <div class="basicText">Winner Documentation. To receive the Prize(s), and as part of the Winner confirmation and verification process, Potential Winner must accurately complete and timely return Company’s Patient Consent form, home impression kit (if applicable), pre-treatment plan photographs, any other documentation reasonably required by Company, and/or any other document necessary to confirm and verify a Potential Winner’s eligibility, as determined by Company, in its sole discretion (collectively, the “Winner Documentation”). Potential Winner must fully and accurately complete Winner Documentation and timely return all such documentation by the date specified by Company, or the Potential Winner may be disqualified and will forfeit any claim to any of the Prizes(s).</div></br>

    <div id="RulesSection11" class="basicText"><strong class="emph2">11. WINNER DISQUALIFICATION:</strong> Reasons a Participant, Potential Winner and/or Winner may be ineligible to receive the Prizes and/or disqualified include, but are not limited to: 
      <strong class="emph2">(i)</strong> failures to meet all of the eligibility requirements stated in the Official Rules, 
      <strong class="emph2">(ii)</strong> failures to abide by these Official Rules, or other instructions of Company, 
      <strong class="emph2">(iii)</strong> failure to provide a valid email address at the time of entry, 
      <strong class="emph2">(iv)</strong> failure to timely respond to the Potential Winner notification email or any other request from Company, 
      <strong class="emph2">(v)</strong> Company is unable to verify the identity and verification of the Potential Winner to its reasonable satisfaction within 7 working days of the relevant drawing of the Potential Winner for the Drawing, 
      <strong class="emph2">(vi)</strong> failure to timely complete and/or return the Winner Documentation, 
      <strong class="emph2">(vii)</strong> the provision of false, incomplete and or misleading information to or about Company, 
      <strong class="emph2">(viii)</strong> the commission of any fraud or deception in connection with any drawing, use of the Website, and/or relationship with Company, 
      <strong class="emph2">(ix)</strong> acting in an obnoxious, threatening, abusive, or harassing manner, at any time before or during the awarding of the Prize, in whole or in part; 
      <strong class="emph2">(x)</strong> inability to timely accept the Prizes for any reason, including, but not limited to, inability to travel to the location where the Prize is being fulfilled and/or  injury or death and 
      <strong class="emph2">(xi)</strong> the inability of Company to timely award or otherwise fulfill the Prizes, due to circumstances beyond Company’s reasonable control, including, but not limited to, legal restrictions, Acts of God, natural disasters, pandemics/epidemics, terrorism, and/or weather. 
    </div></br>

    <div class="basicText">If a Potential Winner or Winner is determined to be ineligible and/or disqualified for any reason, an alternate Potential Winner may be randomly drawn from the eligible entries. Any alternate Potential Winner will be notified as per the procedures outlined in the Official Rules (time permitting). Company reserves the right to modify the Winner notification and/or confirmation and verification procedures connected with the drawing of an alternate Potential Winner, if any.</div></br>

    <div id="RulesSection12" class="basicText"><strong class="emph2">12. PRIZES:</strong> Subject to any restrictions expressly stated in the Official Rules, Winners will receive the Prizes(s) identified on each Drawing’s webpage. Company shall solely determine the specifics of all elements of all Prizes (including timing for fulfillment of Prizes and travel options, if applicable). The approximate retail value of any Prize will be specified in the Drawing Rules. Winners are responsible for ensuring they timely and fully accept the Prize at the specified Prize location(s) and within the specified Prize fulfillment period.</div></br>

    <div class="basicText">Unless explicitly stated otherwise, all Prizes are awarded as is, without any representations or warranties, and cannot be transferred, sold, substituted, or redeemed for cash by Participant. Company reserves the right to substitute a prize of comparable or greater value or cash in Company’s sole discretion. Prize may be subject to availability restrictions in certain jurisdictions based on licensing, import, or distribution regulations or other legal restrictions. In such circumstances, the Winner may be required to take a cash alternative instead. Some restrictions may apply. </div></br>

    <div class="basicText">Unless otherwise noted in Drawing Rules, Winners are solely responsible for all taxes, costs, and fees associated with Prize receipt, ownership, and/or use. Winners may be required to complete and return an IRS W-9 form, W8-BEN, or other applicable forms (i.e., Request for Taxpayer Identification Number and Certification).  Company will not replace any lost or stolen Prize(s) or components of a Prize(s).  </div></br>

    <div class="basicText">All Winners are entitled, at their discretion, to refuse to accept some or all of the Prize(s) that they are eligible to receive and, in such instances, will be deemed to have forfeited the Prize.  If at any time and for any reason, a Winner is deemed to have forfeited a Prize, in whole or in part, or fails to accept delivery of the Prize(s) within a reasonable period, Winner will not be entitled to any alternate Prize or any other compensation of any kind, and Smiles Club shall have no obligation to award the rejected or unclaimed portion of any Prize(s) to an alternative winner.</div></br>

    <div id="RulesSection13" class="basicText"><strong class="emph2">13. PUBLICITY:</strong> Except where prohibited by law, acceptance of any Prize also constitutes Winner’s express agreement and consent for Company, and those acting under Company’s authority, to use Winner’s name, city and state of residence, photographs or other likenesses, pictures, portraits, video, voice, testimonials, biographical information (in whole or in part), and/or statements made by Winner regarding the Drawing, Prize, and/or any of the Drawing Partners, worldwide and in-perpetuity for any and all purposes, including, but not limited to, advertising, trade and/or promotion on behalf of Company (or the applicable Drawing Partners), in any and all forms of media, now known or hereafter devised, including, but not limited to, print, TV, radio, electronic, cable, or World Wide Web, without further limitation, restriction, compensation, notice, review, or approval. Winner agrees to confirm this consent in writing if requested. </div></br>

    <div class="basicText">Some jurisdictions may prohibit Company from conditioning the awarding of a Prize on Winner’s consent to publicity as described in this <span class="highlightTrigger" data-highlight="#RulesSection13">Section 13</span>. Winners who are residents of such jurisdictions and who object to such publicity, must notice Company of such objections via email during the Winner confirmation process. </div></br>

    <div id="RulesSection14" class="basicText"><strong class="emph2">14. GENERAL CONDITIONS:</strong> Company reserves the right to cancel, suspend, and/or modify a drawing, in whole or in part, due to any fraud, bugs, virus, technical failures, changes in the law, or any other factor beyond Company’s reasonable control that impairs the integrity or proper functioning of a drawing, as determined by Company. Company reserves the right, in its sole discretion and at any time, to conduct maintenance for the Website or its systems that may suspend or otherwise impact access to a drawing. Company reserves the right, in its sole discretion, to disqualify any individual tampering, or to attempting to tamper, with the administration and/or operation of any drawing, including without limitations the entry process or the Potential Winner draw process. </div></br>

    <div class="basicText"><strong class="emph1">WARNING: ANY ATTEMPT BY ANY PERSON TO DELIBERATELY DAMAGE ANY WEBSITE ASSOCIATED WITH A DRAWING OR UNDERMINE, OR ATTEMPT TO UNDERMINE, THE LEGITIMATE OPERATION OF A DRAWING WILL BE DISQUALIFIED AND MAY BE SUBJECT TO CRIMINAL AND CIVIL PROSECUTION AND PENALTIES. SHOULD SUCH AN ATTEMPT BE MADE, COMPANY RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW. COMPANY’S FAILURE TO ENFORCE ANY TERMS OF THESE OFFICIAL RULES SHALL NOT CONSTITUTE A WAIVER OF THAT PROVISION. COMPANY RESERVES THE RIGHT TO DISQUALIFY ANY INDIVIDUAL IT FINDS TO BE ATTEMPTING TO TAMPER WITH OR UNDERMINE THE ENTRY PROCESS, THE WEBSITE, AND/OR THE LEGITIMATE OPERATION OF A DRAWING; TO BREACH THE OFFICIAL RULES; OR TO ACT IN AN UNSPORTSMANLIKE OR DISRUPTIVE MANNER OR WITH THE INTENT TO ANNOY, ABUSE, THREATEN, OR HARASS ANY OTHER PERSON.  IF, FOR ANY REASON, THE DRAWING CANNOT BE RUN AS PLANNED OR IF COMPANY DETERMINES IN ITS SOLE DISCRETION THAT THE INTEGRITY OR VIABILITY OF A DRAWING IS COMPROMISED, COMPANY RESERVES THE RIGHT TO VOID ANY ENTRY AT ISSUE AND (A) CANCEL OR TERMINATE THE DRAWING (OR ANY PORTION THEREOF); (B) MODIFY THE DRAWING OR SUSPEND THE DRAWING TO ADDRESS THE IMPAIRMENT AND THEN RESUME THE DRAWING IN A MANNER THAT BEST CONFORMS TO THE SPIRIT OF THESE OFFICIAL RULES; AND/OR (C) AWARD THE PRIZE AT RANDOM FROM AMONG THE ELIGIBLE, NON-SUSPECT ENTRIES RECEIVED UP TO THE TIME OF THE IMPAIRMENT.</strong></div></br>

    <div id="RulesSection15" class="basicText"><strong class="emph2">15. INDEMNIFICATION:</strong> As a condition of entry, each Participant, agrees to defend, indemnify, release and hold harmless Company, all other the Drawing Partners, and their respective parent, subsidiary, and affiliated companies, and any other person or organization responsible for Companying, fulfilling, administering, advertising or promoting a drawing and all of their respective past and present officers, directors, employees, agents and representatives (collectively, the “Released Parties”) from and against any and all claims, expenses, and liability, including but not limited to negligence and damages of any kind to persons and property, including but not limited to invasion of privacy (under appropriation, intrusion, public disclosure of private facts, false light in the public eye or other legal theory), defamation, slander, libel, violation of right of publicity, infringement of trademark, copyright or other intellectual property rights, property damage, or death or personal injury arising out of or relating to a participant’s entry, creation of an entry or submission of an entry, participation in a drawing, acceptance, possession, attendance at, defect in, delivery of, inability to use, use or misuse of Prize (including any travel or activity related thereto) and/or the broadcast, exploitation or use of entry. </div></br>

    <div id="RulesSection16">
      <div class="basicText">
        <strong class="emph2">16. LIMITATIONS OF LIABILITY:</strong> The Released Parties are not responsible and will have no obligation or liability for or resulting from: 
        <strong class="emph2">(i)</strong> a Participant’s entry or attempt to participate in a drawing, 
        <strong class="emph2">(ii)</strong> incorrect or incomplete information, whether caused by a Participant, technical errors or by any of the equipment or programming associated with or utilized in a drawing, 
        <strong class="emph2">(iii)</strong> technical failures of any kind, including, but not limited to outages, malfunctions, interruptions, or disconnections in phone lines or network hardware or software or scheduled or unscheduled maintenance; 
        <strong class="emph2">(iv)</strong> unauthorized human intervention in any part of the entry process or a drawing; 
        <strong class="emph2">(v)</strong> technical or human error in the administration of a drawing or the processing of registrations, 
        <strong class="emph2">(vi)</strong> entries, Prize claims or Prize notifications that are lost, late, incomplete, illegible, unintelligible, damaged, undeliverable or otherwise not received by the intended recipient, in whole or in part, due to computer, human or technical error of any kind (including, for example, SPAM filters); 
        <strong class="emph2">(vii)</strong> Participants who have committed fraud or deception in entering or participating in a drawing or claiming the Prize, 
        <strong class="emph2">(viii)</strong> an inability of the Winner to accept the Prize for any reason; 
        <strong class="emph2">(ix)</strong> an inability to fulfill or award a Prize due to delays or interruptions due to Acts of God, natural disasters, pandemics/epidemics, terrorism, weather or any other similar event beyond Company’s reasonable control; or 
        <strong class="emph2">(x)</strong> any damages, injuries or losses of any kind caused by any Prize or resulting from awarding, acceptance, possession, use, misuse, loss or misdirection of any Prize or resulting from participating in a drawing or any promotion or Prize related activities. If for any reason, a Participant’s entry is confirmed to have been erroneously deleted, lost, or otherwise destroyed or corrupted, Participant’s sole remedy is another entry in a drawing.
      </div>
    </div></br>

    <div id="RulesSection17">
      <div class="basicText"><strong class="emph2">17. BINDING ARBITRATION, NO CLASS ACTION:</strong> Please read this section carefully. Participant understands and agrees that (s)he waives his/her right to sue or go to court to assert or defend his/her rights connected with participation in a drawing. </div></br>

      <div class="basicText">As a condition of participation, Participant and Company agree that any dispute, controversy, or claim arising out of or connected to a drawing, including the determination of the scope or applicability of this arbitration provision, (each, a “Claim”) shall be resolved exclusively by binding arbitration under the procedural rules for commercial disputes set forth in the Comprehensive Arbitration Rules and Procedures of JAMS (“JAMS Rules”), using the Optional Expedited Arbitration Procedures when applicable. The JAMS Rules are available at https://www.jamsadr.com/rules-comprehensive-arbitration/#Rule-16-1. </div></br>

      <div class="basicText"><strong class="emph2">PARTICIPANT AND COMPANY ALSO AGREE THAT (A) ANY CLAIMS WILL BE RESOLVED INDIVIDUALLY, NOT AS A PLAINTIFF OR CLASS REPRESENTATIVE, MEMBER OF, OR OTHERWISE ON BEHALF OF OTHERS IN ANY PURPOSED CLASS, COLLECTIVE, OR REPRESENTATIVE PROCEEDING, AND NOT THROUGH ANY CLASS ACTION, (B) IF A CLAIM PROCEEDS IN COURT ANYWAY, BOTH COMPANY AND PARTICIPANT WAIVE ANY RIGHT TO A JURY TRIAL; AND (C) PARTICIPANT’S REMEDIES ARE LIMITED TO A CLAIM FOR MONEY DAMAGES (IF ANY), AND PARTICIPANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK INJUNCTIVE OR EQUITABLE RELIEF.</strong> (Some jurisdictions do not allow the limitations or exclusion of liability set forth above and in <span class="highlightTrigger" data-highlight="#RulesSection7">Section 7</span>, above, so such language may not apply to every Participant).</div></br>

      <div class="basicText">Participants may seek arbitration of a Claim by contacting <strong class="emph2">Sonrisas, LLC.</strong>  The arbitration may be conducted via telephone, written submissions, or in-person in a mutually agreed location. The arbitrator shall be selected according to the JAMS Rules and Procedures. The arbitrator shall apply Utah law consistent with the Federal Arbitration Act and applicable statutes of limitations and shall honor claims of privilege recognized at law. The arbitrator shall provide a decision explaining his or her findings and conclusions, and the ruling may be entered in any court having jurisdiction thereof. The arbitrator’s decision shall be final and binding upon the Parties. </div></br>

      <div class="basicText">The parties will equally split the arbitrator’s fees, and expenses and each party shall pay its costs and attorneys’ fees. If the claimant can demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, Company will pay as much of the claimant’s filing, and hearing fees in connection with the arbitration as the arbitrator deem necessary to prevent the arbitration from being cost-prohibitive. Notwithstanding, if any party prevails on a statutory claim that affords a prevailing party attorneys’ fees and costs, or if there is a written agreement providing for attorneys’ fees and costs, the arbitrator will award such costs and fees per the applicable statute or written agreement. The arbitrator shall resolve any dispute regarding the reasonableness of any fee or cost that may be awarded under this paragraph. </div></br>

      <div class="basicText">To the extent permitted by law, the existence of the arbitration, the arbitration proceedings, and the outcome of the arbitration will be treated as confidential and will not be disclosed by either party. Participant and Company agree that an award, and any judgment confirming it, only applies to the arbitration in which it was awarded and cannot be used in any other proceeding except to enforce the award itself and any post-arbitration action seeking to enforce an arbitration award or action seeking equitable or injunctive relief shall be brought exclusively in the courts in Utah.</div></br>

      <div class="basicText">If any part of this arbitration provision is deemed to be invalid, unenforceable, or illegal, or otherwise conflicts with the rules and procedures established by JAMS, then the balance of this arbitration provision shall remain in effect and shall be construed in accordance with its terms as if the invalid, unenforceable, illegal or conflicting provision were not contained herein. </div></br>

      <div class="basicText">Notwithstanding the foregoing, either party may seek temporary or emergency equitable relief to enforce its rights in any court of competent jurisdiction. For any Claims brought by that are not subject to arbitration, if any, Participant and Company each: 
        <strong class="emph2">(i)</strong> submit that the exclusive jurisdiction and venue for any such proceeding will be the courts of competent jurisdiction sitting within the State of Utah, (the “Venue”), 
        <strong class="emph2">(ii)</strong> waive any argument that any such court does not have personal jurisdiction or that the Venue is not appropriate or convenient; and 
        <strong class="emph2">(iii)</strong> waive any and all rights to trial by jury for any Claims.</div></br>
    </div>

    <div id="RulesSection18" class="basicText"><strong class="emph2">18. GOVERNING LAW:</strong> All issues and questions concerning the construction, validity, interpretation, and enforceability of the Official Rules, or the rights and obligations of Participants and Company in connection with a drawing, shall be governed by, and construed in accordance with, United States federal law and the laws of the State of Utah, without giving effect to the conflict of laws rules thereof. </div></br>

    <div id="RulesSection19" class="basicText"><strong class="emph2">19. PARTICIPANT'S PERSONAL INFORMATION:</strong> Information collected from Participants is subject to the Company’s Privacy Policy, available at <a href="/privacy" target="_blank">https://www.Smiles.club/privacy</a>.</div></br>


    <div id="RulesSection20" class="basicText"><strong class="emph2">20. COMPANY CONTACT:</strong> <strong class="emph2">Sonrisas, LLC.</strong> , <a href="mailto:support@Smiles.club">support@Smiles.club</a>.</div></br>

    <div id="RulesSection21" class="basicText"><strong class="emph2">21. SEVERABILITY:</strong>  Each of the terms of these Official Rules operates separately.  If any court of relevant authority decides that any of them are unlawful or unenforceable, the remaining terms will remain in full force and effect.</div></br>

    <div id="RulesSection22" class="basicText"><strong class="emph2">22. NO WAIVER:</strong> If Company fails to insist that Participant perform any of Participant’s obligations under these Official Rules, or if Company does not enforce its rights against Participant, or if Company delays in doing so, that will not mean that Company has waived its rights against Participant and will not mean that Participant does not have to comply with these obligations.  If Company does waive a default by Participant, Company will only do so in writing, and that will not mean that Company will automatically waive any later default by Participant.</div></br>

    <div id="RulesSection23" class="basicText"><strong class="emph2">23. ENTIRE AGREEMENT:</strong>  These Official Rules, together with the applicable Drawing Rules, constitute the entire agreement between Company and Participant with respect to the Drawing and supersede all prior or contemporaneous communications, whether electronic, oral or written, between Company and Participant with respect to the Drawing.</div></br>

    <div class="basicText"><strong class="emph2">Copyright © 2021 Sonrisas, LLC.</strong> All third-party names and trademarks are the property of their respective owners.</div></br>
    `
    //CONTENT 30
  }
];

    //<div id="RulesSection20" class="basicText"><strong class="emph2">20. RESULTS:</strong>  A list of winners will be available on <a href="/winners" target="_blank">Smiles.club/winners</a>.</div></br>



	
export default PAGE;
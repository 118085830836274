import 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
//import 'popper.min.js';
import 'LayoutBuilder';
import BasicSiteSettings from 'SITE_SETTINGS.json';
import 'styles/animated.min.css';
import 'styles/googlefonts.css';
import 'styles/scss/global/index.scss';

import InitializePrototypes from 'global/utils/prototypes';
// import GlobalUtil from 'global/utils/global-util';
// window.GlobalUtil = new GlobalUtil({navigate: this.props.navigate});
import 'global/utils/global-util';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {  BrowserRouter } from "react-router-dom";

import App from 'App';
import * as serviceWorker from 'serviceWorker';

import TagManager from 'react-gtm-module'; //GOOGLE GTM MODULE
import API_CALLS from 'API_CALLS/index';


//IF REACT SNAP IS RUNNING
//if (navigator.userAgent !== 'ReactSnap') addScriptTag
const helmetContext = {};

const tagManagerArgs = {
  gtmId: (process.env.NODE_ENV !== 'development') ? process.env.REACT_APP_GMT_ID_DEV : process.env.REACT_APP_GMT_ID,
  //gtmId: ((process.env.NODE_ENV !== 'development') || (navigator.userAgent !== 'ReactSnap')) ? process.env.REACT_APP_GMT_ID_DEV : process.env.REACT_APP_GMT_ID,
  dataLayerName: 'PageDataLayer',
  events: {
    fillOutForm: 'Filling out Form',
    submitForm: 'Submitted Form',
    loadPage: 'Loaded Page'
  }
}


/*
import TagManager from 'react-gtm-module'; //GOOGLE GTM MODULE
//TRIGGER EVENT
const tagManagerArgs = {
  //gtmId: 'GTM-5TM2ZPK', //I DON'T THINK I NEED THIS
  dataLayer: {
    event: 'EVENT_NAME', //STARTED FORM, SUBMITTED FORM, ETC
    userId: '001',
    userProject: 'project',
    language: window.Session.Language,
    page: page.Name
  },
  //dataLayerName: 'PageDataLayer'
}
TagManager.dataLayer(tagManagerArgs)



EXAMPLE 
const tagManagerArgs = {
  dataLayer: {
    event: 'submitForm',
    //OBJ TO SEND WITH SUBMIT FROM
  }
}
TagManager.dataLayer(tagManagerArgs)

fillOutForm

loadPage
*/


window.DefaultLang = BasicSiteSettings.defaultLang; //"ESPAÑOL"; //CHANGE TO ENGLISH TO CHANGE THE DEFAULT LANDING PAGE TO ENGLISH PAGE
InitializePrototypes();
window.GlobalVar = {
	NumberOfDBConnectRetrys: 6,
	TimeBTDBConnectRetrys: 1000
}

window.GlobalUtil.resetWindowSession();
window.SiteSettings = {
  ...window.GlobalUtil.BasicSiteSettings
};
window.TestSession = false; //true; //THIS WILL LET ME ADD TEST CHECKS TO SEE STUFF FLOW WHILE SKIPPING REAL STEPS. LIKE CHECKOUT WILL SKIP CHECKOUT.
// document.addEventListener('scroll', function(e) {
// 	console.log('scrolling')
// });

process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = "0";

TagManager.initialize(tagManagerArgs)
// declare var GlobalUtil;



// var currentOrder = {
//   "hasImpressionKit": true,
//   "products": [
//     {
//         "ID": "61798b3b67e4ce5f5a495d8d",
//         "_id": "61798b3b67e4ce5f5a495d8d",
//         "category": "IMPRESSION_KITS",
//         "cost": 59.99,
//         "description": "Kit de impresión personal a domicilio. El kit incluye compuesto de 2 partes y bandejas bucales para obtener las mejores impresiones. Una vez que se hayan tomado las impresiones, envíelas por correo para obtener su oferta de plan de tratamiento gratuito.\n\n<strong>Kit de impresión personal completo para el hogar.</strong> El kit incluye 4 juegos de masilla de impresión, 2 guantes, 2 bandejas de impresión superiores, 2 bandejas de impresión inferiores y retractor oral de mejillas. Todo lo necesario para obtener las mejores impresiones posibles.\n<br/><br/>\nUna vez que se hayan tomado las impresiones, envíelas por correo para obtener su oferta de plan de tratamiento gratuito.\n<br/><br/>\n<strong>Cuando compre el plan de tratamiento completo, estos $49.00 que pagó por el kit de impresión, ¡Se descontarán del precio del plan de tratamiento completo!</strong>",
//         "discount": 10,
//         "images": [
//           {
//             "createAt": "1654720252588",
//             "customName": null,
//             "id": "62a106fcdd2f246e0677ef150",
//             "name": "impressionKitAlt1Wide.jpg",
//             "order": "",
//             "type": "image/jpeg",
//             "url": "https://sonrisas1.s3.amazonaws.com/PRODUCTS/61798b3b67e4ce5f5a495d8d/IMAGES/1654720247273_impressionKitAlt1Wide.jpg"
//           },
//           {
//             "createAt": "1654720252588",
//             "customName": null,
//             "id": "62a106fcdd2f246e0677ef161",
//             "name": "impressionKitAlt2Wide.jpg",
//             "order": "",
//             "type": "image/jpeg",
//             "url": "https://sonrisas1.s3.amazonaws.com/PRODUCTS/61798b3b67e4ce5f5a495d8d/IMAGES/1654720249134_impressionKitAlt2Wide.jpg"
//           },
//           {
//             "createAt": "1654720252588",
//             "customName": null,
//             "id": "62a106fcdd2f246e0677ef172",
//             "name": "impressionKitAlt3Wide.jpg",
//             "order": "",
//             "type": "image/jpeg",
//             "url": "https://sonrisas1.s3.amazonaws.com/PRODUCTS/61798b3b67e4ce5f5a495d8d/IMAGES/1654720251309_impressionKitAlt3Wide.jpg"
//           }
//         ],
//         "language": "ESPAÑOL",
//         "meta": {
//             "createAt": "1635355451170",
//             "updateAt": "1654720252592"
//         },
//         "name": "Comprar Kit de impresión",
//         "numberInStock": 99999,
//         "ref": "IMPRESSION_KIT",
//         "status": "ACTIVE",
//         "subscription": {
//             "billingCycle": null,
//             "billingCycleCharge": null,
//             "paypalID": null,
//             "setupFee": null
//         },
//         "type": "NORMAL",
//         "website": "SONRISAS",
//         "id": "61798b3b67e4ce5f5a495d8d",
//         "quantity": 1
//     }
//   ],
//   "consent": true
// }
// window.GlobalUtil.State.set("newOrder", {...currentOrder});







// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__APOLLO_STORE__;

// Allow the passed state to be garbage-collected
delete window.__APOLLO_STORE__;


const httpLink = createHttpLink({
  //uri: ((process.env.NODE_ENV === 'development') ? process.env.REACT_APP_GRAPHQL_LINK_DEV : process.env.REACT_APP_GRAPHQL_LINK), `${window.location.origin}:3001/graphql`
  uri: ((process.env.NODE_ENV === 'development') ? "http://localhost:3001/graphql" : process.env.REACT_APP_GRAPHQL_LINK),
  //uri: (((process.env.NODE_ENV === 'development') || (typeof window === 'undefined')) ? process.env.REACT_APP_GRAPHQL_LINK_DEV : process.env.REACT_APP_GRAPHQL_LINK),
});

const authLink = setContext((_, { headers }) => {
  //console.log(`authLink`,"\n\n","\n\n");
        
  // get the authentication token from local storage if it exists
  const token = window.GlobalUtil.LocalStorage.get("token");
  var headersNew = {
    ...headers,
    authorization: token ? `Bearer ${token}` : ""
  }
  if(window.handshaketoken) headersNew.handshaketoken = window.handshaketoken
  // return the headers to the context so httpLink can read them
  return {
    headers: headersNew
  }
});

const client = new ApolloClient({
  initialState: preloadedState,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


window.Client = client;

// Tell react-snap how to save state
window.snapSaveState = () => ({
  //"__APOLLO_STORE__": client.store.getState()
  //__APOLLO_STORE__: client.readQuery({
    //query, // you can pass any query you want to prerender here
    // loginWithToken: API_CALLS.USERS.QUERIES.loginWithToken(),
    // siteSettings: API_CALLS.SITE_SETTINGS.QUERIES.single(),
    // products: API_CALLS.PRODUCTS.QUERIES.all()
  //}),
});



// const AppWithRouter = (
//   <ApolloProvider client={client}>
//     <HelmetProvider context={helmetContext}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </HelmetProvider>
//   </ApolloProvider>
// );


const rootElement = document.getElementById("Root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    document.getElementById("Root"),
    <React.StrictMode>
      <ApolloProvider client={client}>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById('Root'));
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


serviceWorker.unregister();

//import React from 'react';
const PAGE = [
	{
    "content": `Address`
  },
  {
    "content": `Full Name is required before submitting`
  },
  {
    "content": `Address 2`
  },
  {
    "content": `Full Name is required before submitting`
  },
  {
    "content": `Town/City`
  },
  {
    "content": `Full Name is required before submitting`
  },
  {
    "content": `State`
  },
  {
    "content": `Full Name is required before submitting`
  },
  {
    "content": `Country`
  },
  {
    "content": `For now we only ship in the USA`
  },
  {
    "content": `Zipcode`
  },
  {
    "content": `Full Name is required before submitting`
  },
  {
    "content": `Phone`
  },
  {
    "content": `Please include your phone so we can contact you with support.`
  }
];




	
export default PAGE;
const SSCONTENT = `
  <div id="AdminHeader">
    <div class="adminHeaderWrapper">
      <div class="logo">
        <div class="logo-image">
          <a aria-current="page" class="nav-link active" href="/">
            <img src="/static/media/logo-light.328a991a31de90d9eb7d9a961c4aece5.svg" alt="">
          </a>
        </div>
      </div>
      <div class="otherStuff">
        <div class="mobile-button d-block d-sm-none d-md-none d-lg-none">
          <div class="mobile-button-box">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="userLink d-none d-sm-flex d-md-flex d-lg-flex">
          <div class="user">
            <div class="welcome">Welcome,</div>
            <div class="userName">Daniel E. Zaldivar</div>
          </div>
          <div class="userImage">
            <img src="https://sonrisas1.s3.amazonaws.com/USERS/60e0afa6f469340b9e6323bc/PICTURE/1630794830712_squareSmile7.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="AdminContentWrapper">
    <div id="LeftMenu" class="d-none d-sm-flex d-md-flex d-lg-flex">
      <div class="menu">
        <div class="menu-item">
          <a aria-current="page" class="nav-link  active" href="/admin">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              <div class="text">Home</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/shipping">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-shipping-fast"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">To Ship</div>
                <div class="todoNumber">2</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/shipping/sendKit">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Send Kit</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/shipping/sendAligners">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Send Aligners</div>
                      <div class="todoNumber ">2</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/shipping/sendProduct">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Send Product</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/shipping/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/plans">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-file-medical-alt"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Treatment Plans</div>
                <div class="todoNumber">13</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/request">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Request</div>
                      <div class="todoNumber ">11</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/upload">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Upload</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/approve">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Approve</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/userpurchase">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">User Purchase</div>
                      <div class="todoNumber ">2</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/requestFull">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Request Full</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/plans/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/aligners">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-tooth"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Aligners</div>
                <div class="todoNumber">1</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/upload">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Upload</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/schedule">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Schedule</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/make">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Make</div>
                      <div class="todoNumber ">1</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/shipping">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Shipping</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/aligners/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/orders">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-cash-register"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Orders</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/orders/payInvoice">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Paid Order</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/orders/upcoming">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Upcoming</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/orders/failed">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Failed Payments</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/orders/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/blogs">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fab fa-blogger"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Blogs</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/blogs/active">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Active</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/blogs/upcoming">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Upcoming</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/blogs/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/blogs/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/coupons">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-tags"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Coupons</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coupons/active">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Active</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coupons/upcoming">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Upcoming</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coupons/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coupons/expired">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Expired</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coupons/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/products">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-shopping-cart"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Products</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/products/upcoming">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Active</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/products/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/products/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/referrals">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-people-arrows"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Referrals</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/referrals/upcoming">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Active</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/referrals/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/referrals/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/tracking">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-chart-line"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Tracking</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/tracking/active">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Active</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/tracking/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/affiliates">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-user-ninja"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Affiliates</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/affiliates/commissions">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Commissions</div>
                    </div>
                  </div>
                </a>
                <div class="sub-sub-menu-wrapper " style="max-height: 0px;">
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/affiliates/commissions/all">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">All</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/affiliates/commissions/by_user">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">By User</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/affiliates/commissions/paid">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Paid</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/affiliates/influencers">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Influencers</div>
                    </div>
                  </div>
                </a>
                <div class="sub-sub-menu-wrapper " style="max-height: 0px;">
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/affiliates/influencers/all">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">All</div>
                            <div class="todoNumber ">6</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/affiliates/influencers/requested">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Requested</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/giveaways">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-trophy"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Giveaways</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/giveaways/events">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Events</div>
                    </div>
                  </div>
                </a>
                <div class="sub-sub-menu-wrapper " style="max-height: 0px;">
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/events/active">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Active</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/events/pending">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Pending</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/events/expired">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Expired</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/giveaways/contestants">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Contestants</div>
                    </div>
                  </div>
                </a>
                <div class="sub-sub-menu-wrapper " style="max-height: 0px;">
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/contestants/active">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">All</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/contestants/winners">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Winners</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/giveaways/contestants/history">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">History</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/users">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-users"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Users</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/users">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Users</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/admins">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Admins</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/doctors">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Doctors</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/patients">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Patients</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/labs">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Labs</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/users/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/coms">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-comments"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Coms</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coms/new">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">New</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coms/pending">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Pending</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/coms/history">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">History</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link " href="/admin/configure">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-sliders-h"></i>
              </div>
              <div class="text d-flex">
                <div class="texttext">Configure</div>
              </div>
            </div>
          </a>
          <div class="sub-menu-wrapper " style="max-height: 0px;">
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/configure/settings">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Site Settings</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/configure/emails">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Emails</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="sub-menu-item-wrapper">
              <div class="sub-menu-item">
                <a class="nav-link " href="/admin/configure/failed">
                  <div class="inline-nav-link">
                    <div class="icon">
                      <i class="fas fa-angle-right"></i>
                    </div>
                    <div class="text d-flex">
                      <div class="texttext">Failed</div>
                    </div>
                  </div>
                </a>
                <div class="sub-sub-menu-wrapper " style="max-height: 0px;">
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/configure/failed/active">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">Active</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="sub-sub-menu-item-wrapper">
                    <div class="sub-sub-menu-item">
                      <a class="nav-link " href="/admin/configure/failed/history">
                        <div class="inline-nav-link">
                          <div class="icon">
                            <i class="fas fa-caret-right"></i>
                          </div>
                          <div class="text d-flex">
                            <div class="texttext">History</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-sign-out-alt"></i>
              </div>
              <div class="text">Logout</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="AdminContent">
      <div id="Dashboard">
        <div id="" class="container ">
          <div id="" class="row ">
            <div id="" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 ">
              <div class="content">
                <div id="" class="row ">
                  <div id="" class="col ">
                    <div class="content">
                      <div class="section">
                        <div class="title">Page Tracking</div>
                        <div class="card">
                          <div class="cardContent">
                            <div id="DeepPageTracking">
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Full Site</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba150 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba150 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba150 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba150 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba150 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba150 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba150 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba150 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>New Visitors</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba150 ">
                                          <div class="tableValue">10</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba150 ">
                                          <div class="tableValue">10</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba150 ">
                                          <div class="tableValue">34</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba150 ">
                                          <div class="tableValue">43</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba150 ">
                                          <div class="tableValue">79</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba150 ">
                                          <div class="tableValue">36</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba150 ">
                                          <div class="tableValue">230</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba150 ">
                                          <div class="tableValue">16415</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Home Page</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba151 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba151 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba151 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba151 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba151 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba151 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba151 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba151 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>New Visitors</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba151 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba151 ">
                                          <div class="tableValue">12448</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Page deals001</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/deals001
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba152 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba152 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba152 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba152 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba152 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba152 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba152 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba152 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>New Visitors</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba152 ">
                                          <div class="tableValue">3</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba152 ">
                                          <div class="tableValue">5</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba152 ">
                                          <div class="tableValue">561</div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>Add to Cart</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba152 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba152 ">
                                          <div class="tableValue">44</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Page deals002</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/deals002
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba153 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba153 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba153 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba153 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba153 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba153 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba153 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba153 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>New Visitors</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba153 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba153 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba153 ">
                                          <div class="tableValue">2</div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>Add to Cart</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba153 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba153 ">
                                          <div class="tableValue">2</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Page deals003</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/deals003
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba154 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba154 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba154 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba154 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba154 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba154 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba154 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba154 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>New Visitors</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba154 ">
                                          <div class="tableValue">2</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba154 ">
                                          <div class="tableValue">5</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba154 ">
                                          <div class="tableValue">91</div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>Add to Cart</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba154 ">
                                          <div class="tableValue">4</div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>Started Quiz</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba154 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba154 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="">
                                <div class="pageHeader">
                                  <div class="pageName">
                                    <strong>Form</strong>
                                  </div>
                                  <div class="pageURL">
                                    <strong>URL: </strong>/
                                  </div>
                                </div>
                                <div class="tableSection">
                                  <table class="table reportTable">
                                    <thead class="tableHeaders thead-dark">
                                      <tr>
                                        <th class="tableHeader tableHeaderOther">WATCHING</th>
                                        <th class="tableHeader tableHeaderOther tableColTF2e95a48b5583b78ba155 ">TODAY</th>
                                        <th class="tableHeader tableHeaderOther tableColTF3e95a48b5583b78ba155 ">WEEK1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF4e95a48b5583b78ba155 ">WEEK2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF5e95a48b5583b78ba155 ">WEEK3</th>
                                        <th class="tableHeader tableHeaderOther tableColTF6e95a48b5583b78ba155 ">WEEK4</th>
                                        <th class="tableHeader tableHeaderOther tableColTF7e95a48b5583b78ba155 ">MONTH1</th>
                                        <th class="tableHeader tableHeaderOther tableColTF8e95a48b5583b78ba155 ">MONTH2</th>
                                        <th class="tableHeader tableHeaderOther tableColTF9e95a48b5583b78ba155 ">TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody class="tableBody">
                                      <tr>
                                        <th class="tableValues tableValuesNum tableValuesOthers" width="80">
                                          <div class="tableValue category">
                                            <strong>Started Form</strong>
                                          </div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF2e95a48b5583b78ba155 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF3e95a48b5583b78ba155 ">
                                          <div class="tableValue">0</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF4e95a48b5583b78ba155 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF5e95a48b5583b78ba155 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF6e95a48b5583b78ba155 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF7e95a48b5583b78ba155 ">
                                          <div class="tableValue">1</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF8e95a48b5583b78ba155 ">
                                          <div class="tableValue">3</div>
                                        </th>
                                        <th class="tableValues tableValuesNum tableValuesOthers tableColTF9e95a48b5583b78ba155 ">
                                          <div class="tableValue">43</div>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="" class="row ">
                  <div id="" class="col ">
                    <div class="content">
                      <div class="section">
                        <div class="title">System Tracking</div>
                        <div class="card">
                          <div class="cardContent">
                            <table class="table reportTable">
                              <thead class="tableHeaders thead-dark">
                                <tr>
                                  <th class="tableHeader tableHeaderMain">Title</th>
                                  <th class="tableHeader tableHeaderOther tableCol2 ">
                                    <span class="categoryIcon">
                                      <span class="category">Today</span>
                                      <i class="fas fa-calendar-day"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol3 ">
                                    <span class="categoryIcon">
                                      <span class="category">This Week</span>
                                      <i class="fas fa-calendar-week"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol4 ">
                                    <span class="categoryIcon">
                                      <span class="category">1 Week Ago</span>
                                      <i class="fas fa-calendar-week"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol5 ">
                                    <span class="categoryIcon">
                                      <span class="category">2 Weeks Ago</span>
                                      <i class="fas fa-calendar-week"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol6 ">
                                    <span class="categoryIcon">
                                      <span class="category">3 Weeks Ago</span>
                                      <i class="fas fa-calendar-week"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol7 ">
                                    <span class="categoryIcon">
                                      <span class="category">This Month</span>
                                      <i class="fas fa-calendar-alt"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol8 ">
                                    <span class="categoryIcon">
                                      <span class="category">Last Month</span>
                                      <i class="fas fa-calendar"></i>
                                    </span>
                                  </th>
                                  <th class="tableHeader tableHeaderOther tableCol9 ">
                                    <span class="categoryIcon">
                                      <span class="category">Total</span>
                                      <i class="fas fa-calculator"></i>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="tableBody">
                                <tr class="animated slideInRight" style="animation-delay: 0s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Aligners</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">212</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.05s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Blogs</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">9</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.1s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Coupons</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">14</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.15s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Products</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">14</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.2s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Coms</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">14</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.25s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Orders</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">29</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.3s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New TreatmentPlans</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">26</div>
                                  </th>
                                </tr>
                                <tr class="animated slideInRight" style="animation-delay: 0.35s;">
                                  <th class="tableValues tableValuesMain">
                                    <div class="tableValue">
                                      <strong>New Users</strong>
                                    </div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol2 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol3 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol4 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol5 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol6 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol7 ">
                                    <div class="tableValue">1</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol8 ">
                                    <div class="tableValue">0</div>
                                  </th>
                                  <th class="tableValues tableValuesNum tableValuesOthers tableCol9 ">
                                    <div class="tableValue">41</div>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 fullHeight">
              <div class="content">
                <div class="section">
                  <div class="title">Quick Links</div>
                  <div class="newUserButtons">
                    <button class="button button1 bigIconButtons">
                      <span>New User</span>&nbsp;&nbsp; <i class="fas fa-user"></i>
                    </button>
                    <button class="button button1 btn-color5 bigIconButtons">
                      <span>New Patient</span>&nbsp;&nbsp; <i class="fas fa-user-injured" style="cursor: pointer;"></i>
                    </button>
                    <button class="button button1 btn-color4 bigIconButtons">
                      <span>New Doctor</span>&nbsp;&nbsp; <i class="fas fa-user-md"></i>
                    </button>
                  </div>
                </div>
                <div class="section">
                  <div class="title">Checklist</div>
                  <div class="card">
                    <div class="cardContent" style="margin: -20px;">
                      <div id="TodoList">
                        <div class="lists">
                          <div class="activeNotes">
                            <div class="clickToAddNew">Click to Add New</div>
                          </div>
                          <div class="inactiveNotes">
                            <div class="inactiveNotesTitle">
                              <div class="text">Inactive List</div>
                              <i class="fas fa-minus "></i>
                            </div>
                            <div class="hidden">
                              <div class="quickNoteWrapper inactive">
                                <div class="quickNote">
                                  <div class="icon">
                                    <i class="fas fa-square"></i>
                                    <i class="far fa-check-square"></i>
                                  </div>
                                  <div class="timeAndDate">
                                    <div class="title">
                                      <div class="text">
                                        <span>Buy Boxes</span>
                                      </div>
                                      <div class="trash">
                                        <i class="fas fa-trash"></i>
                                      </div>
                                    </div>
                                    <div class="date">
                                      <i class="fas fa-calendar"></i>
                                      <span>11/26/2022&nbsp;&nbsp;&nbsp;7:07PM</span>
                                    </div>
                                  </div>
                                </div>
                                <hr>
                              </div>
                              <div class="quickNoteWrapper inactive">
                                <div class="quickNote">
                                  <div class="icon">
                                    <i class="fas fa-square"></i>
                                    <i class="far fa-check-square"></i>
                                  </div>
                                  <div class="timeAndDate">
                                    <div class="title">
                                      <div class="text">
                                        <span>Pick winners</span>
                                      </div>
                                      <div class="trash">
                                        <i class="fas fa-trash"></i>
                                      </div>
                                    </div>
                                    <div class="date">
                                      <i class="fas fa-calendar"></i>
                                      <span>11/26/2022&nbsp;&nbsp;&nbsp;7:07PM</span>
                                    </div>
                                  </div>
                                </div>
                                <hr>
                              </div>
                              <div class="quickNoteWrapper inactive">
                                <div class="quickNote">
                                  <div class="icon">
                                    <i class="fas fa-square"></i>
                                    <i class="far fa-check-square"></i>
                                  </div>
                                  <div class="timeAndDate">
                                    <div class="title">
                                      <div class="text">
                                        <span>Get over 100 contestants </span>
                                      </div>
                                      <div class="trash">
                                        <i class="fas fa-trash"></i>
                                      </div>
                                    </div>
                                    <div class="date">
                                      <i class="fas fa-calendar"></i>
                                      <span>11/26/2022&nbsp;&nbsp;&nbsp;7:07PM</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="newNoteButtonWrapper">
                          <button class="newNoteButton button button2">
                            <i class="fas fa-notes-medical"></i>
                            <span>New Note</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
`

export default SSCONTENT;
export {SSCONTENT};

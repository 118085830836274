import React, { useState, useRef } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import FormBuilder from 'global/components/FormBuilder/index';
const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}

const FORM_FIELDS = (CONTENT)=>{
  return(
    [ //defaultValuePath used to set default value form object
      {
        title: <div className="formTitle"><span className="">{CONTENT[3]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[4]}</span></div>,
        type: "text",
        name: "address,street",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,street",
        errorMessage: CONTENT[5]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[6]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[7]}</span></div>,
        type: "text",
        name: "address,street2",
        required: false,
        defaultValue: "",
        defaultValuePath: "address,street2"
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[8]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[9]}</span></div>,
        type: "text",
        name: "address,city",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,city",
        errorMessage: CONTENT[10]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[11]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[12]}</span></div>,
        type: "selectState",
        name: "address,state",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,state",
        errorMessage: CONTENT[13]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[14]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[15]}</span></div>,
        type: "text",
        name: "address,country",
        required: true,
        defaultValue: "",
        defaultValuePath: "address,country",
        errorMessage: CONTENT[16]
      },
      {
        title: <div className="formTitle"><span className="">{CONTENT[17]}</span></div>,
        subTitle: <div className="formSubTitle"><span className="">{CONTENT[18]}</span></div>,
        type: "text",
        name: "address,zipcode",
        maxNumChar: 5,
        required: true,
        defaultValue: "",
        defaultValuePath: "address,zipcode",
        errorMessage: CONTENT[19]
      }
    ]
  )
};






class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateIsValid: false,
      curObj: this.props.curObj
    };    
  }

  onSubmit = async() => {
    var {curObj} = this.state;
    await this.props.onUpdateUser(curObj, window.ForceUserReload);
    return true;
  }

  render(){
    var {updateIsValid, curObj} = this.state;
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    return(
      <i className="fas fa-pencil-alt" onClick={(e)=>{
        e.preventDefault();
        window.GlobalUtil.triggerEvent("SlideInRightEvent", {
          TITLE: this.props.CONTENT[0],
          closeButtonText: this.props.CONTENT[1],
          saveButtonText: this.props.CONTENT[2],
          customClass: "frontEndSlideInRight shortSlideInRight",
          width: "700px",
          onSave: this.onSubmit,
          children: (props)=>{                                      
            return(
              <FormBuilder
                {...props} 
                disableKeySubmit={true}
                initialValues={curObj}
                listOfFields={FORM_FIELDS(CONTENT)}
                onSubmit={this.onSubmit}
                onChange={newObj => this.setState({curObj: newObj})}
                onValidate={props.onChangeValid}
              />
            )
          }
        });
      }} />
    )
  }
}


export default Index;
      

///Users/danielezaldivar/Sites/sonrisas/src/styles/website/pages/clearaligners.scss
import React from 'react';
//import LazyLoad from 'react-lazyload';
import 'styles/scss/website/pages/giveaways.scss';
import TemplatePage from 'website/templates/templatePage/index.js';
import {NavLink, Link} from 'react-router-dom';
import JoinGiveawayForm from 'global/components/JoinGiveawayForm/index.js';
import HeaderImage from 'website/components/HeaderImage/index';

import NumberCounter from './comp/NumberCounter';
import CountDown from './comp/CountDown';


import MainFooter_500 from 'global/img/squareGirlAligner_500.jpg';
import Main1 from 'global/img/main_pngs/main1.png';
import Square0 from 'global/img/squareGirlAlignerHappy_500.jpg';
import Square2 from 'global/img/schools/square2_500.jpg';
import PrizeImage1 from 'global/img/giveaway1/prizeImage_1_2x1_500.jpg';
import PrizeImage2 from 'global/img/giveaway1/prizeImage_2_2x1_500.jpg';
import PrizeImage3 from 'global/img/giveaway1/prizeImage_3_2x1_500.jpg';
import GalleryALigner from 'global/img/smileGallery_500.jpg';
import ESPAÑOL from "./ESPAÑOL";
import ENGLISH from "./ENGLISH";
import RULES_ESPAÑOL from "./RULES_MAY_22_ESPAÑOL.js";
import RULES_ENGLISH from "./RULES_MAY_22_ENGLISH.js";
import LayoutBuilder from 'LayoutBuilder';


const CONTENTS = {
  ESPAÑOL,
  ENGLISH
}
const RULES_CONTENTS = {
  "ESPAÑOL": RULES_ESPAÑOL,
  "ENGLISH": RULES_ENGLISH
}
const IMAGES = [
  Square0,
  Main1,
  Square2,
  GalleryALigner
];

const {Container, Row, Column} = LayoutBuilder;
class Index extends TemplatePage {
  constructor(props) {
    super(props);
    this.state = {
     ...this.state
    };    
    this.highlightRefArray = [];
  }

  didMount(){
    //span class="highlightTrigger" data-highlight="#RulesSection5A"
    Array.from(document.querySelectorAll('.highlightTrigger')).forEach((el, index)=>{
      this.highlightRefArray.push(el);
      el.addEventListener("mouseenter", this.onMouseEnter);
      el.addEventListener("mouseleave", this.onMouseEnter);
    });
  }

  willUnmount(){
    this.highlightRefArray.map((el, index)=>{
      el.removeEventListener("mouseenter", this.onMouseEnter);
      el.removeEventListener("mouseleave", this.onMouseLeave);
    })
  }

  onMouseEnter = (event)=>{        
    var highlight = event.target.dataset.highlight;
    if(highlight) document.querySelector(highlight).classList.toggle("highlight"); 
  }

  onMouseLeave = (event)=>{
    var highlight = event.target.dataset.highlight;
    if(highlight) document.querySelector(highlight).classList.toggle("highlight"); 
  }

  render(){
    //var {showSideMenu} = this.state;          
    const CONTENT = window.GlobalUtil.dangerSetGlobal(CONTENTS[window.Session.Language]); 
    const RULES = window.GlobalUtil.dangerSetGlobal(RULES_CONTENTS[window.Session.Language]); 
    
    return (
      <div className="contentPage" id="PageGiveaway">
        <div className="anchorDiv"><div id="TopMainSectionTop" /></div>
        <div id="TopMainSection" className="containerSection backgroundImage4">
          <Container className="">
            <Row className="">
              <Column className="r1c2" col="" xs="" sm="" md="" lg="" xl="">
                <div className="bannersquare imageDiv">
                  <HeaderImage
                    img={IMAGES[0]}
                    alt="women with invisible aligner"
                    right={true}
                  />
                </div>
                <CountDown/>
                <br/>
                <br/>
              </Column>
              <Column className="r1c1 contentCenter" col="12" xs="12" sm="12" md="12" lg="8" xl="8">
                <div className="innerTextWrapper">
                  <div id="GiveawayFormAnchor"><div id="GiveawayForm"></div></div>
                  <div  className="">
                    {CONTENT[0]}
                    {CONTENT[1]}
                    <div className="bodySize1 giveawayFormDesc">
                      {CONTENT[2]}
                    </div>
                    <br/>
                    {<JoinGiveawayForm 
                      pageInfo={this.props.pageInfo}
                      GID={(process.env.NODE_ENV !== 'development') ? "62abef93f6f6d70adbc150d9" : "62acc7590080162d22e2c3e6"}
                      successMessage={CONTENT[35]}
                    />}
                  </div>
                  <h2 className="headerSize1">{CONTENT[36]}</h2>
                </div>
              </Column>
            </Row>
          </Container>
        </div>


       <div className="anchorDiv"><div id="Row2Anchor" /></div>
          <div id="HowItWorks">
            <br/>
            <br/>
            <Container className=""> 
              <Row className=" row1">
                <Column className="r1c1 text-center" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="preheader2">
                    {CONTENT[3]}
                  </div>
                  <div className="headerSize1">
                    {CONTENT[4]}
                  </div>
                  <div className="bodySize2">
                    {CONTENT[5]}
                  </div>
                  <br/>
                  <Row className="row2 howItWorks3Boxes">
                    <Column className="r2c1" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                      <div className="howItWorks3BoxesBox">
                        <div className="prizeImages">
                          <img src={PrizeImage1} alt="" />
                        </div>
                        <div className="vector1 backgroundColor5">
                          <i className="fas fa-crown"></i>
                        </div>
                        {CONTENT[6]}
                        {CONTENT[7]}
                        
                      </div>
                    </Column>
                    <Column className="r2c2" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                      <div className="howItWorks3BoxesBox">
                        <div className="prizeImages">
                          <img src={PrizeImage2} alt="" />
                        </div>
                        <div className="vector1 backgroundColor7">
                          <i className="fas fa-trophy"></i>
                          {/*<span style={{"fontWeight":"700"}}>50</span><i className="fas fa-percentage" style={{"fontSize":"30px"}}></i>*/}
                        </div>
                        {CONTENT[8]}
                        {CONTENT[9]}
                        
                      </div>
                    </Column>
                    <Column className="r2c3" col="12" xs="12" sm="12" md="12" lg="4" xl="4">
                      <div className="howItWorks3BoxesBox">
                        <div className="prizeImages">
                          <img src={PrizeImage3} alt="" />
                        </div>
                        <div className="vector1 backgroundColor1">
                          <i className="fas fa-award"></i>
                          {/*<i className="fas fa-dollar-sign"></i>*/}
                        </div>
                        {CONTENT[10]}
                        {CONTENT[11]}
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Container>

            <Container className="">
              <Row className="">
                <Column className="r1c2" col="12" xs="12" sm="12" md="12" lg="6" xl="5">
                  <div className="bannersquare imageDiv">
                    <img src={IMAGES[3]} alt="" />
                  </div>
                  <br/>
                  <br/>
                </Column>
                <Column className="r1c1 contentCenter" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="innerTextWrapper">
                    <div className="preheader">
                      {CONTENT[12]}
                    </div>
                    <div className="headerSize1">
                      {CONTENT[13]}
                    </div>
                    <div className="bodySize1">
                      {CONTENT[14]}
                    </div>
                    <div className="buttonGroup">
                      <NavLink 
                        className={"button button1"} 
                        onClick={()=>{}} 
                        to={`${this.props.match.url}`}>
                          {CONTENT[15]}
                      </NavLink>
                      <NavLink 
                          className={"button button2"} 
                          onClick={()=>{}} 
                          to={`${this.props.match.url}#Rules`}>
                            {CONTENT[16]}
                        </NavLink>
                    </div>
                    <br/>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>

          <div className="anchorDiv"><div id="Row3Anchor" /></div>
            <div id="AlignersCostTooMuch" className="animated fadeIn">
              <div className="costContainer1">
                <Container> 
                  <Row className="row1">
                    <Column className="r1c1" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                      <div className="innerTextWrapper headerText">
                        <div className="headerSize3">
                          {CONTENT[17]}
                        </div>
                        <div className="headerSize1">
                          {CONTENT[18]}
                        </div>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </div>
              <div className="costContainer2">
                <Container> 
                  <Row className="row1">
                    <Column className="r1c2" col="12" xs="12" sm="12" md="12" lg="12" xl="12">
                      <div className="tooMuchExamples">
                        <Row className="row1">
                          <Column className="r1c1" col="12" xs="6" sm="6" md="6" lg="6" xl="3">
                            <div className="tooMuchExample">
                              <div className="priceTitle">{CONTENT[19]}</div>
                              <div className="cost"><small>{CONTENT[23]}</small> <NumberCounter className="price" countBy={15} countTo={6500} /></div>
                            </div>
                          </Column>
                          <Column className="r1c1" col="12" xs="6" sm="6" md="6" lg="6" xl="3">
                            <div className="tooMuchExample">
                              <div className="priceTitle">{CONTENT[20]}</div>
                              <div className="cost"><small>{CONTENT[23]}</small> <NumberCounter className="price" countBy={15} countTo={7000} /></div>
                            </div>
                          </Column>
                          <Column className="r1c1" col="12" xs="6" sm="6" md="6" lg="6" xl="3">
                            <div className="tooMuchExample">
                              <div className="priceTitle">{CONTENT[21]}</div>
                              <div className="cost"><small>{CONTENT[23]}</small> <NumberCounter className="price" countBy={15} countTo={3200} /></div>
                            </div>
                          </Column>
                          <Column className="r1c1" col="12" xs="6" sm="6" md="6" lg="6" xl="3">
                            <div className="tooMuchExample">
                              <div className="priceTitle">{CONTENT[22]}</div>
                              <div className="cost"><NumberCounter className="price" countBy={20} countTo={1600} /></div>
                            </div>
                          </Column>
                        </Row>
                      </div>
                    </Column>
                  </Row>
                </Container>
              </div>
            </div>


          <div className="anchorDiv"><div id="Row4Anchor" /></div>
          <div id="WhoAreWe" className="animated slideInRight">
            <Container > 
              <Row className="row1">
                <Column className="r1c1" col="12" xs="12" sm="12" md="12" lg="5" xl="4">
                  <div className="bannersquare imageDiv">
                    <img src={IMAGES[1]} alt="" />
                  </div>
                </Column>
                <Column className="r1c2 contentCenter" col="12" xs="12" sm="12" md="12" lg="7" xl="8">
                  <div className="innerTextWrapper">
                    <div className="preheader">{CONTENT[24]}
                    </div>
                    <div className="headerSize1">
                      {CONTENT[25]}
                    </div>
                    <div className="bodySize1">
                      {CONTENT[26]}
                    </div>
                    <NavLink 
                      className={"button button1"} 
                      onClick={()=>{}} 
                      to={`${this.props.match.url}`}>
                        {CONTENT[27]}
                    </NavLink>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>



          <div className="anchorDiv"><div id="Row5Anchor" /></div>
          <div id="Rules" className="animated fadeIn">
            <Container className="basicRowWrapper"> 
              <Row className="row1">
                <Column className="r1c1" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="preheader2">{CONTENT[28]}
                  </div>
                  <div className="headerSize1">
                    {RULES[0]}
                  </div>
                  <div className="bodySize1">
                    {RULES[1]}
                  </div>
                  <NavLink 
                    className={"button button1"} 
                    onClick={()=>{}} 
                    to={`${this.props.match.url}`}>
                      {CONTENT[15]}
                  </NavLink>
                  <br/>
                </Column>
              </Row>
            </Container>
          </div>

          
          



          <div className="anchorDiv"><div id="Row7Anchor" /></div>
          <div id="BusinessContact">
            <Container className=""> 
              <Row className="row1">
                <Column className="r1c1 " col="12" xs="12" sm="12" md="12" lg="3" xl="3">
                  <div className="bannersquare imageDiv">
                    <HeaderImage
                      img={MainFooter_500}
                      alt="women with invisible aligner"
                      right={true}
                    />
                  </div>
                </Column>
                <Column className="r1c2 contentCenter" col="" xs="" sm="" md="" lg="" xl="">
                  <div className="textCenter">
                    <div className="preheader2">
                      {CONTENT[29]}
                    </div>
                    <div className="headerSize1">
                      {CONTENT[30]}
                    </div>
                    <div className="bodySize1">
                      {CONTENT[31]}
                      <div className="">
                        <button className={"button button2"} style={{"margin": "20px 5px 0"}} onClick={()=>window.GlobalUtil.triggerEvent("ShowContactForm", true)}>{CONTENT[32]}</button>
                      </div>
                    </div>
                  </div>
                </Column>
              </Row>
            </Container>
          </div>

      </div>
    );
  }
}




export default Index;
      
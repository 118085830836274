//import React from 'react';
const PAGE = [
  {
    "type": "div",
    "content": `<div class="preHeader">¡REGALO GRATIS!</div>`
  },//CONTENT 0
  {
    "type": "div",
    "content": `<h1 class="sectionTitle">¡Regístrese para ganar <span class="emph">hasta un 50% de descuento</span> en tu sonrisa perfecta!</h1>`
  },//CONTENT 1
  {
    "type": "div",
    "content": `Para registrarse, solo debe completar el siguiente formulario. Al completar el formulario, usted acepta recibir mensajes relacionados con este sorteo y acepta todas las condiciones que se encuentran en las reglas a continuación. Debe ser mayor de 18 años para registrarse.`
    //CONTENT 2
  },
  {
    "content": `LO QUE ESTAMOS SORTEANDO`
    //CONTENT 3
  },
  {
    "type": "span",
    "content": `¡Regístrese para tener la oportunidad de <span class="emph">ganar UN DESCUENTO DEL 50%!</span>`
    //CONTENT 4
  },
  {
    "content": `Este sorteo ("SORTEO") tendrá los siguientes premios: Un (1) Gran Premio – Un tratamiento de Alineadores Transparentes GRATIS para el 1er lugar, tres (3) tratamientos de Alineadores Transparentes con DESCUENTO para el 2do lugar, seis (6) tratamientos de Alineadores Transparentes con DESCUENTO para el 3er lugar y un descuento general para todos los participantes que no hayan sido premiados en el 1er, 2do y 3er lugar. El "Período de Inscripción" de este sorteo será desde mayo al junio de 2022. Después del sorteo, los ganadores y todos los participantes recibirán una notificación por correo electrónico con instrucciones sobre cómo obtener o rechazar sus premios. Sin obligación.`
    //CONTENT 5
  },
  {
    "type": "div",
    "className": "headerSize3",
    "content": `<div class="headerSize3">¡El 1er lugar recibirá </div><div class="largeText emph">UN DESCUENTO DEL 50%!</div>`
    //CONTENT 6
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">¡Los ganadores del 1er premio obtendrán un <strong>TRATAMIENTO COMPLETO</strong> por solo <span class="emph">$725 USD!</span></div>
                <div class="bodySize2">Todos los descuentos se aplican al ya increíble precio reducido de $1,849 USD para un tratamiento completo de Alineadores Transparentes. Consulte a continuación las condiciones y restricciones.</div>`
    //CONTENT 7
  },
  {
    "type": "div",
    "className": "headerSize3",
    "content": `<div class="headerSize3">¡El 2do lugar recibirá </div><div class="largeText emph">UN DESCUENTO DEL 40%!</div>`
    //CONTENT 9
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">¡Los ganadores del 2do premio obtendrán un <strong>TRATAMIENTO COMPLETO</strong> por solo <span class="emph">$870 USD!</span></div>
                <div class="bodySize2">Estos descuentos no se pueden aplicar al plan de pago de suscripción. Puede haber otros términos y condiciones. Consulte a continuación las condiciones y restricciones.</div>`
    //CONTENT 10
  },
  {
    "type": "div",
    "className": "headerSize3",
    "content": `<div class="headerSize3">¡El 3er lugar recibirá </div><div class="largeText emph">UN DESCUENTO DEL 30%!</div>`
    //CONTENT 12
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">¡Los ganadores del 2do premio obtendrán un <strong>TRATAMIENTO COMPLETO</strong> por solo <span class="emph">$1,015 USD!</span></div>
                <div class="bodySize2">¡Imagina obtener esa sonrisa que siempre has querido por un precio tan bajo! Puede haber otros términos y condiciones. Consulte a continuación las condiciones y restricciones.</div>`
    //CONTENT 13
  },
  {
    "content": `¡PERO ESO NO ES TODO!`
    //CONTENT 8
  },
  {
    "content": `Otros premios`
    //CONTENT 8
  },
  {
    "type": "div",
    "content": `
      <p>Reciba $150 de descuento sobre sobre el precio de oferta de $ 1,849, ¡solo por participar!</p>

      <p>Después del sorteo, los ganadores y todos los participantes recibirán una notificación por correo electrónico con instrucciones sobre cómo obtener o rechazar sus premios. Ninguna obligación.</p>

      <p>Puede proceder a canjear esta oferta sin esperar. Envíenos un correo electrónico a <a href="mailto:support@Smiles.club">support@Smiles.club</a> con su nombre y número de teléfono y uno de nuestros agentes se comunicará con usted con instrucciones sobre cómo proceder.</p>

      <p>Si después obtiene uno de los tres premios, Smiles Club le reembolsará la diferencia de precio</p>`
    //CONTENT 8
  },
  {
    "content": `Únete ahora`
    //CONTENT 8
  },
  {
    "content": `Ver reglas`
    //CONTENT 11
  },
  {
    "type": "span",
    "content": `¡Los alineadores cuestan <strong>DEMASIADO!</strong>`
    //CONTENT 15
  },
  {
    "content": `¡Déjanos ayudarte!`
    //CONTENT 16
  },
  {
    "content": `Alineadores tradicionales`
    //CONTENT 17
  },
  {
    "content": `INVISALIGN`
    //CONTENT 18
  },
  {
    "content": `Otras compañías`
    //CONTENT 19
  },
  {
    "content": `Nuestro Precio Base`
    //CONTENT 20
  },
  {
    "content": `Hasta`
    //CONTENT 21
  },
  {
    "content": `¿QUIENES SOMOS?`
    //CONTENT 22
  },
  {
    "content": `Somos un laboratorio dental que está harto de precios tan altos!`
    //CONTENT 23
  },
  {
    "type": "div",
    "content": `<p>Hemos estado proporcionando a los dentistas alineadores de alta calidad y otros artefactos dentales durante años. Hemos notado cuanto inflan los precios cuando venden alineadores transparentes. Como padres que tuvieron que pagar esos altos precios por los alineadores transparentes, entendemos el costo familiar que representan.</p>
      <p>Así que creamos esta empresa para ver si podemos ayudar a otros padres a ahorrar algo de dinero. Permítanos proporcionarle alineadores de alta calidad para sus hijos a precios accesibles.</p>`
    //CONTENT 24
  },
  {
    "content": `Únete ahora`
    //CONTENT 25
  },
  {
    "content": `REGLAS Y CONDICIONES COMPLETAS`
    //CONTENT 26
  },
  {
    "content": `OPORTUNIDADES DE NEGOCIO`
    //CONTENT 29
  },
  {
    "content": `Únete a nuestro equipo`
    //CONTENT 30
  },
  {
    "type": "div",
    "content": `
      <p>Si está interesado en una oportunidad de trabajar con nosotros vendiendo alineadores transparentes, nos gustaría saber de usted. Buscamos vendedores motivados y amigables. Horarios flexibles y grandes oportunidades de crecimiento disponibles.</p>
      <p>Si tiene alguna otra pregunta comercial, ¡nos ENCANTARÁ ayudarlo!</p>
    `
    //CONTENT 31
  },
  {
    "content": `Contáctenos`
    //CONTENT 32
  },
  {
    "type": "div",
    "content": `
      <div id="SuccessMessage" class="animated fadeIn message">
        <div class="messageText">
          <div>
            <div class="icon"><i class="fas fa-laugh-beam"></i></div>
            <h2>
              ¡Has entrado en nuestro sorteo!
            </h2>
            <div class="text1">
              Tu código de cupón de <strong>$150</strong> de descuento es
            </div>
            <div class="text2">
              <strong>[GIVEAWAY_150_OFF_202201]</strong>
            </div>
            <div class="text3">
              <p>Esto es válido para la compra de cualquiera de nuestros planes de tratamiento con alineadores completos a precio completo. El cupón vence el 1 de abril de 2022. Nos pondremos en contacto con los ganadores en la información de contacto proporcionada. Consulte las Reglas oficiales a continuación para obtener más detalles. Póngase en contacto con nosotros si tiene alguna pregunta.</p>
            </div>
          </div>
        </div>
      </div>
    `
    //CONTENT 33
    //Form JOIN Success Message
  },
  {
    "content": `Este sorteo ha terminado. Vuelve para nuestros futuros sorteos.`
    //CONTENT 36
  }
];




  
export default PAGE;
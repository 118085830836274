import React from 'react';
import API_CALLS from 'API_CALLS/index';
import Loading from 'global/components/Loading/index';
import {Link} from 'react-router-dom';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      keywords: null
    };    
  }
  
  componentDidMount(){
    //console.log("Recent Post componentDidMount")
    this.RunAPIOnceWhenReady = window.GlobalUtil.runAPIOnceWhenReady(this.getRecentPosts);
    //this.getRecentPosts();
  }

  componentWillUnmount(){
    this.LoadingAPI = false;
    this.RunAPIOnceWhenReady.unsubscribe();
  }

  getRecentPosts = async () => {
    //console.log('getRecentPosts');
    if(this.LoadingAPI) return;
    if(!window.Client || !window.Client.query) return false;
    this.setState({loading: true});
    //console.log("variables","\n\n",variables,"\n\n");
    this.LoadingAPI = true;
    //console.log('getRecentPosts RSN');
    return await window.Client.query({
      query: API_CALLS.BLOGS.QUERIES.all(),
      variables: {
        limit: 5,
        offset: 0,
        sortBy: "duration.startAt",
        dates: [
          {
            "field": "duration.startAt",
            "lt": `${Date.now()}`
          }
        ],
        manual: [
          {
            path: "language",
            val: window.Session.Language
          }
        ]
      },
      fetchPolicy: "cache"
    })
    .then(result => {
      this.LoadingAPI = false;
      this.setState({
        loading: false,
        posts: result.data.blogs
      });
    })
    .catch(error => {
      this.LoadingAPI = false;
      console.log('error', error);
      this.setState({
        loading: false
      });
    });
  }



  render(){
    var {loading, posts} = this.state;
    return(
      <div id="RecentPosts" className="sideMenuItem">
        <div className="sectionMenuTitle">Recent Posts</div>
        <ul className="recentPostsUl">
          {
            (posts && posts.length)
            ? <React.Fragment>
                {
                  posts.map((blog, index)=>{
                    return(
                      <li key={index}>
                        <Link className={"nav-link"}  to={"/blog/"+blog.url}>
                          <div className="numberPost">
                            <div className="number">{index+1}</div>
                            <div className="post">
                              <div className="title">{window.GlobalUtil.ulify(blog.title, 54)}</div>  
                              <div className="date">{window.GlobalUtil.dateBasic(blog.duration.startAt)}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </React.Fragment>
            : loading
              ? <Loading 
                  floating={false}
                  header=""
                  body=""
                  inlineStyles={{
                    "loadingIcon": {
                      "width": "40px",
                      "height": "40px",
                      "margin": "0px",
                      "marginLeft": "20px",
                    },
                    "loadingHeader": {
                      "marginBottom": "5px",
                      "fontSize": "40px"
                    },
                    "loadingBody": {
                      "marginBottom": "0px",
                      "fontSize": "20px"
                    }
                  }}
                />
              : <p className="">Failed to Load</p>
          }
        </ul>
      </div>
    )
  }
}


export default Index;
      
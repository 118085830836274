//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club's Clear Aligners Blog all things about invisible aligner",
			"DESCRIPTION": "Our blog is full of information on Clear Aligners, Invisible Aligners, Braces, and more. Smiles Club is on a mission to redefine clear ortho care.",
			"KEYWORDS": "Blog, Clear Aligners, Clear aligners Work, invisible aligners work, aligners blog, smiles club, smile club, clear smiles, smiles aligners, clear braces, braces invisible, blog posts, information aligners, info aligners"
		},
		"CONTENT": {
			"HeaderText": [
				{
					"type": "div",
					"className": "headerZoneText",
					"content": `
						<div class="preHeader">Latest Posts</div>
						<h1 class="sectionTitle">Blog Posts</h1>
						<div class="">
							<p>Hello, smile enthusiasts! At Smiles Club, we're on a mission to redefine the way you experience orthodontic care. Welcome to our Clear Aligners Blog, your go-to source for all things related to achieving a confident, radiant smile with the convenience of clear aligners. Our blog is crafted with a mission to empower and guide individuals on their orthodontic journey. Dive into a wealth of knowledge covering topics such as "clear aligner benefits," "invisible braces technology," and "online orthodontic consultation," providing you with the insights needed to make informed decisions about your smile transformation.</p>
							<p>We understand that navigating the world of orthodontics can be overwhelming, and that's why we've created a blog that not only educates but also fosters a community seeking reliable guidance. Smiles Club Blog is more than just information; it's a space where individuals can find support, inspiration, and resources to embark on their journey to a confident smile.</p>
						</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="preHeader">Blog Posts</div>
						<h1 class="sectionTitle">Our Latest News</h1>
						<div class="">Praesent tincidunt aliquet diam eget sollicitudin. Donec ornare enim eu lacus facilisis, vitae vulputate lorem ullamcorper. Quisque lacus turpis, fermentum sit amet massa non, mollis rutrum sapien. Nullam vehicula magna nisi, et tincidunt nisl mattis sed. Vivamus a porta nisl. Nam interdum eget ipsum eu egestas</div>
					`
				}
			],
			"LoadingText": [
				{
					"content": "Loading"
				},
				{
					"content": "Loading Recent Blog Posts"
				}
				
			],
			"Button1": [
				{
					"content": `Load More` //LOAD MORE BUTTON TEXT
				}
			],
			"BlogPost": [
				{
					"content": `Date`
				},
				{
					"content": `Read More`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
			"DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"HeaderText": [
				{
					"type": "div",
					"className": "headerZoneText",
					"content": `
					<h1 class="sectionTitle">Nuestras Ultimas Noticias</h1>
					<div class="">Praesent tincidunt aliquet diam eget sollicitudin. Donec ornare enim eu lacus facilisis, vitae vulputate lorem ullamcorper. Quisque lacus turpis, fermentum sit amet massa non, mollis rutrum sapien. Nullam vehicula magna nisi, et tincidunt nisl mattis sed. Vivamus a porta nisl. Nam interdum eget ipsum eu egestas</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="preHeader">Publicaciones del blog</div>
						<h1 class="sectionTitle">Nuestras Ultimas Noticias</h1>
						<div class="">Praesent tincidunt aliquet diam eget sollicitudin. Donec ornare enim eu lacus facilisis, vitae vulputate lorem ullamcorper. Quisque lacus turpis, fermentum sit amet massa non, mollis rutrum sapien. Nullam vehicula magna nisi, et tincidunt nisl mattis sed. Vivamus a porta nisl. Nam interdum eget ipsum eu egestas</div>
					`
				}
			],
			"LoadingText": [
				{
					"content": "Cargando"
				},
				{
					"content": "Cargando Publicaciones de Blog Recientes"
				}
			],
			"Button1": [
				{
					"content": `Carga más` //LOAD MORE BUTTON TEXT
				}
			],
			"BlogPost": [
				{
					"content": `Fecha`
				},
				{
					"content": `Lee Mas`
				}
			]
		}
	}
};


	
export default PAGE;

//import React from 'react';
const PAGE = [
  //LOADING MESSAGE
  { //CONTENT 0
    "content": `Enviando`
  },
  { //CONTENT 1
    "content": `Agregándote a nuestro sorteo`
  },

  //API SUCCESS MESSAGES
  { //CONTENT 2
    "content": `¡Has sido agregado!`
  },
  { //CONTENT 3
    "content": `Nos pondremos en contacto contigo una vez que hayamos elegido a los ganadores.`
  },

  //API FAILED MESSAGES
  { //CONTENT 4
    "type": "span",
    "content": `<strong>¡OH NO!</strong>`
  },
  { //CONTENT 5
    "content": `Algo parece estar mal`
  },
  { //CONTENT 6
    "content": `Por favor inténtalo de nuevo más tarde.`
  },


  { //CONTENT 7
    "content": `Nombre`
  },
  { //CONTENT 8
    "content": `Teléfono`
  },
  { //CONTENT 9
    "content": `Elige una escuela`
  },
  { //CONTENT 10
    "content": `Por favor, elija una escuela de la lista. Si está educando en casa, simplemente elija cualquier escuela para apoyar.`
  },
  { //CONTENT 11
    "content": `Idioma preferido`
  },
  { //CONTENT 12
    "content": `¿En qué idioma prefiere ser contactado?`
  },
  { //CONTENT 13
    "content": `REQUERIDO`
  },
  { //CONTENT 14
    "content": `Este campo es obligatorio antes de enviar`
  },
  { //CONTENT 15
    "content": `Envíar`
  },


 

  //API EMAIL ALREADY USED MESSAGES
  { //CONTENT 16
    "type": "span",
    "content": `<strong>¡PERDÓN!</strong>`
  },
  { //CONTENT 17
    "content": `Parece que ya se está utilizando el correo electrónico.`
  },
  { //CONTENT 18
    "content": `Por favor, intente un correo electrónico diferente.`
  },
];


	
export default PAGE;
//import React from 'react';
const PAGE = [
  //LOADING MESSAGE
  { //CONTENT 0
    "content": `Sending`
  },
  { //CONTENT 1
    "content": `Adding you to our giveaway`
  },


  //API SUCCESS MESSAGES
  { //CONTENT 2
    "content": `You've been added!`
  },
  { //CONTENT 3
    "content": `We will contact you once we've chosen any winners.`
  },
  //API FAILED MESSAGES
  { //CONTENT 4
    "type": "span",
    "content": `<strong>OH NO!</strong>`
  },
  { //CONTENT 5
    "content": `Something seems to have gone wrong`
  },
  { //CONTENT 6
    "content": `Please try again later.`
  },





  { //CONTENT 7
    "content": `Name`
  },
  { //CONTENT 8
    "content": `Phone`
  },
  { //CONTENT 9
    "content": `Pick a School`
  },
  { //CONTENT 10
    "content": `Please choose a school. If you are home schooling, simply choose any school to support.`
  },
  { //CONTENT 11
    "content": `Preferred Language`
  },
  { //CONTENT 12
    "content": `What language would you prefer to be contacted?`
  },
  
  
  { //CONTENT 13
    "content": `REQUIRED`
  },
  { //CONTENT 14
    "content": `This field is required before submitting`
  },

  { //CONTENT 15
    "content": `Enter Now`
  },


  

  

  //API EMAIL ALREADY USED MESSAGES
  { //CONTENT 16
    "type": "span",
    "content": `<strong>SORRY!</strong>`
  },
  { //CONTENT 17
    "content": `It seems that email is already being used.`
  },
  { //CONTENT 18
    "content": `Please try a different email.`
  },
];




  
export default PAGE;
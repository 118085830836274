//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners financial options and cost for invisible braces.",
			"DESCRIPTION": "Smiles Club provides Stripe pay later financial orthodontics options. See our clear aligner prices and discounts.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, clearbraces, clear-braces, invisible braces, best aligners, Stripe, Affirm, Afterpay, discount aligners, safest aligners, number one clear aligners, invisible aligners, safe plastics, discreetly, clearaligners alternative"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Price and Plans</div>
						<h1 class="sectionTitle">Custom treatment plan at less than half the price.</h1>
						<div class="">An Orthodontist will design a customized treatment plan and will show you some videos about the transformation of your smile along the treatment. You will decide on a payment option and your clear aligners will be fabricated and delivered to you. </div>
					`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "A woman's smile pulling out her clear aligners."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT OPTIONS</div>
						<h2 class="sectionTitle2">Two easy ways to pay!</h2>
						<div class="subHeader">We offer the same or higher quality treatment you will get in a regular dental office at less than half the price. Our clear aligners are far less expensive than traditional braces. So say goodbye to financial barriers and embrace the possibilities of achieving your dream smile through our partnership with Affirm and Afterpay!</div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">Single Payment</div>
						<div class="paymentAmmount" style="color: #0070e0;">$2,199</div>
						<div class="summary">Pay upfront using your FSA or HSA card, your bank account, Credit cards, or other simply pay with Affirm payment plans. Lock this amazing deal today and save more by paying all at once! Contact us if you have any questions or for more details.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<!--<div class="bigIcon"><i class="fab fa-stripe" style="color: #003087;"></i></div>-->
						<div class="typeTitle">SMILE NOW, Pay Later!</div>
						<div class="paymentAmmount" style="color: #0070e0;">Pay over time with Affirm</div>
						<div class="summary">Smiles Club is integrated with Affirm's "Buy now, pay over time" program. You can choose to split the cost into 6-38 monthly payments. Checking your eligibility won't affect your credit.*</div>
					`
				},
				{
					"type": "span",
					"content": `<span class="responsiveSmall">*Visit Affirm's official website at www.affirm.com, to learn more about their "Buy now, pay over time" program.</span>`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Buy Now`
				},
				{
					"type": "span",
					"content": `Buy Now`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PAYMENT TYPES</div>
						<h2 class="sectionTitle2">We accept </h2>
						<div class="subHeader">Regular credit cards, Stripe, Affirm, Cashapp, Amazon Pay, Google Pay, Afterpay, FSA, HSA, Bank Accounts ACH, And Bank wires.</div>
					`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Get Started`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "Amex payment Icon"
				},
				{
					"alt": "Stripe payment Icon"
				},
				{
					"alt": "Affirm payment Icon"
				},
				{
					"alt": "Cashapp payment Icon"
				},
				{
					"alt": "Discover payment Icon"
				},
				{
					"alt": "Google payment Icon"
				},
				{
					"alt": "Amazon payment Icon"
				},
				{
					"alt": "Afterpay payment Icon"
				},
				{
					"alt": "Visa payment Icon"
				},
				{
					"alt": "Mastercard payment Icon"
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "Sonrisas Club proporciona opciones de ortodoncia financiera de pago posterior de Affirm.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenostransparentes, frenos transparentes, frenos-transparentes, frenos invisibles, los mejores alineadores, pago posterior de Affirm, Stripe, alineadores con descuento, los alineadores más seguros, los alineadores transparentes número uno, los alineadores-invisibles, los plásticos seguros, discretamente, la alternativa de los alineadores transparentes"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">PRECIO Y PLANES</div>
						<h1 class="sectionTitle">Plan de tratamiento personalizado a menos de la mitad del precio.</h1>
						<div class="">Un Ortodoncista diseñará un plan de tratamiento personalizado y te mostrará algunos videos sobre la transformación de tu sonrisa a lo largo del tratamiento. Tu decidirás una opción de pago y tus alineadores transparentes serán fabricados y enviados a tu domicilio.</div>`
				}
			],
			"TopMainSectionImages": [
				{
					"alt": "La sonrisa de una mujer sacándose los alineadores transparentes."
				}
			],
			"HowToPay": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">OPCIONES DE PAGO</div>
						<h2 class="sectionTitle2">¡Dos formas sencillas de pagar!</h2>
						<div class="subHeader">Ofrecemos el mismo tratamiento o mejor del que obtendrás en un consultorio dental regular a menos de la mitad del precio.</div>
					`
				}
			],
			"PaymentOptions": [
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fas fa-cash-register" style="color: #0070e0;"></i></div>
						<div class="typeTitle">Pago único</div>
						<div class="paymentAmmount" style="color: #0070e0;">$2,199</div>
						<div class="summary">¡Paga por adelantado, usando tu tarjeta FSA o HSA, o tu cuenta bancaria, Affirm, Cash app, Amazon Pay, Cash App, Google Pay etc. ¡Cierre esta increíble oferta hoy! Póngase en contacto con nosotros si tiene alguna pregunta o para obtener más detalles.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<div class="bigIcon"><i class="fab fa-stripe" style="color: #003087;"></i></div>
						<div class="typeTitle">¡SONRÍE AHORA, paga después!</div>
						<div class="paymentAmmount" style="color: #0070e0;">Affirm Buy now, pay over time</div>
						<div class="summary">Smiles Club está integrado con el programa "Buy now, pay over time" de Affirm. Puede elegir dividir el costo en 6-38 meses.*</div>
					`
				},
				{
					"type": "span",
					"content": `<span class="responsiveSmall">*Visite el sitio web oficial de Affirm en www.affirm.com para obtener más información sobre su programa "Buy now, pay over time".</span>`
				}
			],
			"PaymentOptionsButtons": [
				{
					"type": "span",
					"content": `Comprar`
				},
				{
					"type": "span",
					"content": `Comprar`
				}
			],
			"PaymentTypes": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">FORMAS DE PAGO</div>
						<h2 class="sectionTitle2">Aceptamos</h2>
						<div class="subHeader">Tarjetas de crédito regulares, Stripe, Affirm, Cashapp, Amazon Pay, Google Pay, Afterpay, FSA, HSA, Bank Accounts ACH, y transferencias bancarias.</div>
						`
				}
			],
			"PaymentTypesButton": [
				{
					"type": "span",
					"content": `Contáctanos`
				}
			],
			"PaymentTypesImages": [
				{
					"alt": "El symbolo de Amex"
				},
				{
					"alt": "El symbolo de Stripe"
				},
				{
					"alt": "El symbolo de Affirm"
				},
				{
					"alt": "El symbolo de Cashapp"
				},
				{
					"alt": "El symbolo de Discover"
				},
				{
					"alt": "El symbolo de Google"
				},
				{
					"alt": "El symbolo de Amazon"
				},
				{
					"alt": "El symbolo de Afterpay"
				},
				{
					"alt": "El symbolo de Visa"
				},
				{
					"alt": "El symbolo de Mastercard"
				}
			]
		}
	}
}


	
export default PAGE;

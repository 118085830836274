import React from 'react';
import {  Routes, Route, useLocation } from "react-router-dom";
import Confetti from 'react-confetti';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
//import API_CALLS from 'API_CALLS/index';
import {ChangeLang, GetLan} from 'NavLanConverter';
import Website from 'website/index';
import Checkout from 'website/pages/checkout/index';
//import { SS_CONTENT_STRING as CheckoutSSL } from 'website/pages/checkout/index';
import BackendWrapper from 'BackendWrapper';
import TemplateSlideInRight from 'global/templates/templateSlideInRight/index';
import WithLocationHook from 'global/components/WithLocationHook/index';
import Cart from 'global/components/Cart/index';
import Loading from 'global/components/Loading/index';
import {GetBasicUserInfoAsync, GetCurrentPage, DangerSet} from 'global/utils/helper-functions';
import APISaveForTracking from 'API_CALLS/TRACKING/TRACKING_FUNCTIONS';


//Custom Landing Pages for GOOGLE ADS
import CustomLandingPage1 from 'website/pages/customLandingPages/page1.js';
import CustomLandingPage2 from 'website/pages/customLandingPages/page2.js';
import CustomLandingPage3 from 'website/pages/customLandingPages/page3.js';


// import LayoutBuilder from 'LayoutBuilder';

// const {Container, Row, Column} = LayoutBuilder;



// const httpLink = createHttpLink({
//   uri: 'http://localhost:3001/graphql',
//   credentials: 'same-origin'
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });


// const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache()
// });
// const client = new ApolloClient({
//   uri: 'http://localhost:3001/graphql',
//   // batchInterval: 10,
//   // opts: {
//   //   credentials: 'same-origin',
//   // },

//   cache: new InMemoryCache()
// });

//


// if(loading) return (
//       <Loading
//         header={undefined}
//         body={undefined}
//         floating={true}
//         overlay={true}
//         inlineStyles={{
//           loadingIcon: {
//             "maxWidth": "250px"
//           }
//         }}
//       />
//     )
const SERVER_RENDER_DEFAULT_SITE_INFO = {
  "Language": "ENGLISH",
  "lastUpdate": undefined,
  "token": undefined,
  "urlParams":{},
  "user": {
    "role": "USER",
    "ID": undefined,
    "website": "SMILESCLUB",
    "preferredLanguage": "ENGLISH",
    "address": {},
    "treatmentPlan": {}
  }
}






class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      loading: false
    };
  }



  componentDidMount(){
    this._ismounted = true;
    this.setupSystem();
    console.log('App componentDidMount this.props', this.props);
    window.navigation = this.props.navigation;
    
    //console.log('navigator.userAgent', navigator.userAgent);
    //console.log('process.env.NODE_ENV ', process.env.NODE_ENV );
    //console.log(`app componentDidMount`,"\n\n","\n\n");
    
    //ONCE IT'S SETUP ONCE IT WONT DO IT AGAIN. THIS IS JUST INCASE
    setTimeout(()=>{ //WHEN BOTSONIC SHOWS UP, THEN LISTEN TO IT'S HEIGHT CHANGE
      this.chatBotListenerSetup();
      setTimeout(()=>{ //WHEN BOTSONIC SHOWS UP, THEN LISTEN TO IT'S HEIGHT CHANGE
        this.chatBotListenerSetup();
        setTimeout(()=>{ //WHEN BOTSONIC SHOWS UP, THEN LISTEN TO IT'S HEIGHT CHANGE
          this.chatBotListenerSetup();
        }, 1500)
      }, 1500)
    }, 1500)


    window.GlobalUtil.triggerEvent("RouterEvent", this.props.location); //ROUTER IS READY THEN RUN THIS EVENT
    
    //GetLan(); //GET WHAT LENG THIS PAGE IS
    if(window.Client) window.GlobalUtil.triggerEvent("ClientEvent", window.client); //ROUTER IS READY
    //window.GlobalUtil.triggerEvent("ClientEvent", window.Client);
    this.setState({WindowClientReady: true});

    if(window.Client) this.APITracking();


    
    //window.GlobalUtil.triggerEvent("PageChangedEvent", newPage);
    this.setupCart();
  }

  componentWillUnmount(){
    this._ismounted = false;
    this.HandleCartOrdersKey.unsubscribe();
    this.LanguageEventKey.unsubscribe();
    this.SiteSettingsEventKey.unsubscribe();
    this.PageChangeEventKey.unsubscribe();
    this.SlideInRightEventKey.unsubscribe();
    this.showConfettiTimeoutKey.unsubscribe();
    this.showLightboxImagesTimeoutKey.unsubscribe();
    if(this.ClientEventKey) this.ClientEventKey.unsubscribe();
    if(this.RouterEventEventKey) this.RouterEventEventKey.unsubscribe();
  }

  setupSystem = () => {
    this.PageAlreadySubmitted = (window.GlobalUtil.LocalStorage.get("setPageAsViewed") ? window.GlobalUtil.LocalStorage.get("setPageAsViewed") : false);         
    this.HandleCartOrdersKey = window.GlobalUtil.State.subscribe("newOrder", newOrd=>{
      window.GlobalUtil.setCartToLocalStorage(newOrd); //UPDATE TEMP CACHE
    });
    this.LanguageEventKey = window.GlobalUtil.subscribeEvent("LanguageEvent", newLang=>{       
      //console.log(`LanguageEvent`,"\n\n",newLang,"\n\n");
      if(!newLang) return; //IF NO CHANGE THEN RETURN
      if(window.Session && window.Session.Language && (window.Session.Language !== newLang)){
        //FIND CURRENT PAGE
        //GO TO CORRECT LENGUAGE PAGE VERSION OF THIS PAGE
        //window.GlobalUtil.LocalStorage.set("language", newLang);
        //window.Session.Language = newLang;
        ChangeLang({newLang: newLang})

        // ENGLISH
        this.setState({
          date: new Date(),
          reload: true
        }, ()=>this.setState({
          reload: false
        }));
      }
    });

    this.SiteSettingsEventKey = window.GlobalUtil.subscribeEvent("SiteSettingsEvent", cleanObj=>{
      window.SiteSettings = {...window.SiteSettings, ...window.GlobalUtil.APICleanObj(cleanObj)};
      if(this._ismounted) this.setState({date: new Date()});
    });


    this.PageChangeEventKey = window.GlobalUtil.subscribeEvent("PageChangedEvent", newPage=>{ //RUNS EVERY TIME THE PAGE CHANGES
      //window.GlobalUtil.consoleLogNew({LOCATION:`src/App.js`, NAME:`PAGE_CHANGE_EVENT_TRIGGERED`, newPage});
      
      //GetLan(); //ON PAGE CHANGE CHECK IF LAN CHANGED. IF SO THEN UPDATE TO NEW LANG. ELSE SKIP    
      
      if(window.NoScroll){ //IF WE DO NOT WANT TO SCROLL THEN STOP HERE THIS ONE TIME
        window.NoScroll = false;
        return true;
      }

      if(!window.Router){ //IF NO ROUTER THEN LOAD WHEN ROUTER EXIST AND STOP HERE
        this.RouterEventEventKey = window.GlobalUtil.subscribeEventOnce("RouterEvent", ()=>{
          window.GlobalUtil.triggerEvent("PageChangedEvent", newPage);
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        });
        return;
      }
      
      var hash = window.GlobalUtil.deepGetFromString(window, 'Router,history,location,hash', false);  
      //console.log(`hash`,"\n\n",hash,"\n\n");
      window.GlobalUtil.consoleLogNew({LOCATION:`src/App.js`, NAME:`PageChangeEvent`, CONTENT:newPage});
      if (!hash || (hash === '')) {
        //console.log('scroll should have worked');
        window.scrollTo({
          top: 0,
          left: 0,
          //behavior: 'instant'
        });
      } else {
        clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          // if (element) {
          //   element.scrollIntoView();
          // } 
          if(!element)return;
          var top = element.scrollHeight;
          window.scrollTo({
            top: top,
            left: 0,
            //behavior: 'instant'
          }); 
        }, 0);
      }
      // setTimeout(()=>{
      //   window.scrollTo(0,0);
      // }, 10);
    });


    this.SlideInRightEventKey = window.GlobalUtil.subscribeEvent("SlideInRightEvent", newTemplateDBObj=>{
      var {SlideInRightEvn} = this.state;
      if(!SlideInRightEvn) SlideInRightEvn = {};
      SlideInRightEvn[window.GlobalUtil.getRandomId()] = newTemplateDBObj;
      this.setState({SlideInRightEvn});            
    });

    this.showConfettiTimeoutKey = window.GlobalUtil.subscribeEvent("ShowConfetti", showOrNot=>{
      //console.log(`this.showConfettiTimeout`,"\n\n",this.showConfettiTimeout,"\n\n");
      if(this.showConfettiTimeout) return;
      this.showConfettiTimeout = true; //this is running
      this.setState({showConfetti: true});
    });


    this.showLightboxImagesTimeoutKey = window.GlobalUtil.subscribeEvent("ShowLightboxImages", images=>{
      //if(this.showLightboxImagesTimeout) return;
      //this.showLightboxImagesTimeout = true; //this is running
      this.setState({showLightboxImages: images});
    });


    this.ClientEventKey = window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{            
      //ONCE THE CLIENT EVEN IS READY THEN LOAD ANY BASIC USER THAT COULD EXIST
      GetBasicUserInfoAsync({
        noCache: true
      });
      this.APITracking()
    });
  }
  

  setupCart = () => {
    //GET ANY PRODUCTS THAT WHERE IN THE CART AND LEFT IN THE TEMP MEMORY
    window.GlobalUtil.State.set("newOrder", window.GlobalUtil.getCartFromLocalStorage());
  }

  APITracking = () => {          
    //return;
    //console.log('APITracking', GetCurrentPage());
    if(this.PageAlreadySubmitted) return;
    if(window.Session && window.Session.user && window.Session.user._id) {
      window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
      this.PageAlreadySubmitted = true; //IF IS ALREADY EXISTING USER THEN TRACK NOTHING
      return; 
    } else {
      this.PageAlreadySubmitted = true;
      var currentPage = GetCurrentPage();
      APISaveForTracking({page: currentPage.Name, url: (currentPage.Paths ? currentPage.Paths[0] : window.location.pathname.toLowerCase()), type: "PAGE"})
        .then(()=>{
          window.GlobalUtil.LocalStorage.set("setPageAsViewed", true);
          this.PageAlreadySubmitted = true;
          window.handshaketoken = undefined;     
        });
    }
  }

  chatBotListenerSetup = () => {
    if(!document.getElementById('Botsonic-wrapper')) return;
    if(this.chatBotListenerSetupWorked) return; //IF WE'VE ALREADY SET IT UP THEN DON'T DO IT AGAIN
    this.chatBotListenerSetupWorked = true;
    this.chatBotClassToggle();
    var observer = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
          this.chatBotClassToggle()
        });    
    });
    
    var target = document.getElementById('Botsonic-wrapper');
    observer.observe(target, { attributes : true, attributeFilter : ['style'] });
  }

  chatBotClassToggle = () => {
    if(!document.querySelector("#Botsonic-wrapper")) return;
    clearTimeout(this.chatBotClassToggleTimeout);
    this.chatBotClassToggleTimeout = setTimeout(()=>{
      var botsonicWrapper = document.querySelector("#Botsonic-wrapper");
      if(botsonicWrapper){
        var maxHeight = document.querySelector("#Botsonic-wrapper").style.maxHeight;
        if((maxHeight !== "100%") && (Number(maxHeight.slice(0,-2)) < 300)){//If min hight is less than 300px or not equal to 100% then keep the icons behind the side menu
          //botsonicWrapper.classList.toggle("inactive", true)
          document.querySelector("#Botsonic-wrapper").classList.value = "inactive";
        } else {
          //botsonicWrapper.classList.toggle("inactive", false)
          document.querySelector("#Botsonic-wrapper").classList.value = "";
        }
      }
    }, 500)
  }

  render(){
    var {WindowClientReady, reload, loading, SlideInRightEvn, showConfetti, showLightboxImages, styleToggle} = this.state;     
    var location = (typeof window === 'undefined') ? this.props.location : window.location;
    var Session = (typeof window === 'undefined') ? SERVER_RENDER_DEFAULT_SITE_INFO : window.Session;
    
    // if(window && window.Router){
    //   window.Session.Language = window.DefaultLang;
    // }
    
    if(loading) return (
      <div className="">
        <Loading 
          floating="true"
          header="Loading"
          body="Loading you session"
          inlineStyles={{
            "loadingIcon": {
              "paddingBottom": "20px"
            },
            "loadingHeader": {
              "marginBottom": "5px",
              "fontSize": "40px"
            },
            "loadingBody": {
              "marginBottom": "0px",
              "fontSize": "20px"
            }
          }}
          />
      </div>
    );
    // return (
    //   <div id="SmilesClub">
    //     <Routes>
    //       <Route path="*" element={<h1>Testing</h1>}/>
    //     </Routes>
    //     </div>
    // )
    //if((!reload && !loading)) return <div id="SmilesClub"></div>;
    return (
      <div id="SmilesClub">
        <Routes> {/*location={((process.env.NODE_ENV === 'development') ? '/' : '/')}*/}
          {/*//IF WE ARE PRE RENDERING LET'S MAKE THE BACKEND STARTER FILES WITH THE INITIAL FILES
            (typeof window === 'undefined') ? <Route path="/admin" element={<div id="Admin"></div>}/> : null
          */}
          {/*//IF WE ARE PRE RENDERING LET'S MAKE THE BACKEND STARTER FILES WITH THE INITIAL FILES
            (typeof window === 'undefined') ? <Route path="/user" element={<div id="UserWrapper" className="animated fadeIn"></div>}/>   : null
          */}
          <Route path="/user/*" element={<BackendWrapper location={location} path="/user" />} />
          <Route path="/doctor/*" element={<BackendWrapper location={location} path="/doctor" />} />
          <Route path="/admin/*" element={<BackendWrapper location={location} path="/admin" />} />
          <Route path="/lab/*" element={<BackendWrapper location={location} path="/lab" />} />
          
          {/* <Route 
            path="/checkout/:PID" 
            loader={({ params }) => {
              // window.GlobalUtil.triggerEvent("PageChangedEvent", {
              //   PageName: "Checkout",
              //   Name: "Checkout",
              //   Paths: ["/checkout"]
              // });
              return {...params}
            }}
            element={<Checkout {...this.props} pageInfo={{
              "PageName":"Checkout",
              "Name":"Checkout",
              "Paths": ["/checkout"],
              "Menu":  [""]
            }}/>} 
          /> */}


       
          <Route 
            path="/checkout//*" 
            loader={({ params }) => {
              // window.GlobalUtil.triggerEvent("PageChangedEvent", {
              //   PageName: "Checkout",
              //   Name: "Checkout",
              //   Paths: ["/checkout"]
              // });
              return {...params}
            }}
            element={<Checkout {...this.props} pageInfo={{
              "PageName":"Checkout",
              "Name":"Checkout",
              "Paths": ["/checkout"],
              "Menu":  [""]
            }}/>} 
          />
          
          <Route path="/deals001" element={<CustomLandingPage1 {...this.props} location={location} /> } />
          <Route path="/deals002" element={<CustomLandingPage2 {...this.props} location={location} /> } />
          <Route path="/deals003" element={<CustomLandingPage3 {...this.props} location={location} /> } />
          <Route path="*" element={<Website {...this.props} Session={Session} location={location} />}/> 
        </Routes>  
          {
            (SlideInRightEvn && Object.keys(SlideInRightEvn).map((key, index)=>{
                var TemplateObj = SlideInRightEvn[key];
                if(!this.SlideInRightRef) this.SlideInRightRef = {};
                return(
                  <TemplateSlideInRight
                    key={key}
                    ref={e=>this.SlideInRightRef[key]=e}
                    title={TemplateObj.TITLE}
                    icon={TemplateObj.ICON}
                    closeIcon={TemplateObj.CLOSEICON}
                    isValid={(TemplateObj.ISVALID !== undefined) ? TemplateObj.ISVALID : true}
                    width={TemplateObj.width}
                    customClass={TemplateObj.customClass}
                    onClose={()=>{
                      if(TemplateObj.onClose) TemplateObj.onClose();
                      delete SlideInRightEvn[key];
                      this.setState({SlideInRightEvn});
                    }}
                    noHeader={TemplateObj.noHeader}
                    noFooter={TemplateObj.noFooter}
                    saveButtonText={TemplateObj.saveButtonText}
                    hideSaveButton={TemplateObj.hideSaveButton}
                    closeButtonText={TemplateObj.closeButtonText}
                    onSave={()=>{
                      var OnCloseFunction = (this.SlideInRightRef[key] && this.SlideInRightRef[key].onClose) ? this.SlideInRightRef[key].onClose : ()=>{};
                      if(TemplateObj.onSave){
                        TemplateObj.onSave().then(()=>{
                          OnCloseFunction();
                        });
                      } else OnCloseFunction();
                    }}
                    customButtons={
                      TemplateObj.CUSTOMBUTTONS 
                      ? TemplateObj.CUSTOMBUTTONS
                      : null
                    }>
                    {TemplateObj.children({
                      onChangeValid: (newValid)=>{
                        SlideInRightEvn[key].ISVALID = newValid;
                        this.setState({SlideInRightEvn})
                      },
                      onSetSubmitButton: (newSaveButton)=>{
                        TemplateObj.onSave = newSaveButton;
                        this.setState({date: new Date()})
                      }
                    })}
                  </TemplateSlideInRight>
                ) 
              })
            )
          }
          <Cart
          />
          {
            showConfetti && 
            <div style={{"position":"fixed","top":"0","bottom":"0","left":"0","right":"0","pointerEvents":"none","zIndex":"1000"}}>
              <Confetti
                numberOfPieces={1000}
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={false}
                tweenDuration={8000}
                onConfettiComplete={()=>{
                  this.setState({showConfetti: false});
                  this.showConfettiTimeout = false;
                }}
              />
            </div>
          }
  
  
          { 
            showLightboxImages &&
            <Lightbox 
              images={showLightboxImages} 
              onClose={()=>{
                window.GlobalUtil.triggerEvent("ShowLightboxImages", []);
              }}
            />
          }
          {/* <div id="Botsonic-wrapper" 
            onClick={()=>{
              this.setState({styleToggle: !styleToggle});
            }}
            style={styleToggle ? {"maxHeight":"534px","maxWidth":"410px"} : {"maxHeight":"700px","maxWidth":"470px"}}
          >
            <iframe id="widget-Botsonic" style={{"height":"100%","width":"100%"}} title="Widget - Botsonic" allow="fullscreen" allowtransparency="true" role="dialog" src="https://d2nnr6irhfmb65.cloudfront.net/CDN/index.html?service-base-url=https%3A%2F%2Fapi.botsonic.ai&amp;token=0c28994a-c673-44b7-a8bb-0ee1dda3478d&amp;base-origin=http%3A%2F%2Flocal-host%3A3000&amp;instance-name=Botsonic"></iframe>
          </div> */}
          {/* <div id="Botsonic-wrapper">
            <iframe 
              //style={{"height":"100vh","width":"100vw"}} 
              src="https://widget.writesonic.com/CDN/index.html?service-base-url=https://api.botsonic.ai&token=0c28994a-c673-44b7-a8bb-0ee1dda3478d&base-origin=https://bot.writesonic.com&instance-name=Botsonic&standalone=true&page-url=https://smiles.club/?t=connect&workspace_id=201dade9-57d9-443d-a706-d1c42d437ea1" >
            </iframe>
          </div> */}
      </div>
    );
  }
}





export default WithLocationHook(App);

export {
  SERVER_RENDER_DEFAULT_SITE_INFO
};
const SSCONTENT = `
<div id="User">
  <div id="UserContent">
    <div id="NavMenu">
      <a aria-current="page" class="siteLogo active" href="/user">
        <img src="/static/media/logo-dark.558a9666b4dd1cf1f71d0b64448a3675.svg" alt="">
      </a>
      <div class="menu ">
        <div class="menuTitle">Menu</div>
        <div class="menu-item">
          <a aria-current="page" class="nav-link active" href="/user/">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              <div class="text">Dashboard</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/user/treatmentplan">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-file-contract"></i>
              </div>
              <div class="text">TreatmentPlan</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/user/affiliate">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-user-astronaut"></i>
              </div>
              <div class="text">Affiliate</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/user/profile">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-user"></i>
              </div>
              <div class="text">Profile</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/user/billing">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-money-check"></i>
              </div>
              <div class="text">Billing</div>
            </div>
          </a>
        </div>
        <div class="menu-item">
          <a class="nav-link" href="/user/help">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-question-circle"></i>
              </div>
              <div class="text">Help</div>
            </div>
          </a>
        </div>
      </div>
      <div class="menu otherButtons">
        <div class="menu-item">
          <a class="nav-link">
            <div class="inline-nav-link">
              <div class="icon">
                <i class="fas fa-sign-out-alt"></i>
              </div>
              <div class="text">Logout</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="Content">
      <div id="Header">
        <div id="" class="container-fluid ">
          <div id="" class="row ">
            <div id="" class="col mobileHeader">
              <div class="content">
                <a aria-current="page" class="siteLogo active" href="/user">
                  <img src="/static/media/logo-dark.558a9666b4dd1cf1f71d0b64448a3675.svg" alt="">
                </a>
                <div class="mobileButtonBox">
                  <i class="fas fa-bars"></i>
                </div>
              </div>
            </div>
            <div id="" class="col mainHeader">
              <div class="content">
                <div class="info d-flex"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="UserHome" class="scrollZone">
        <div class="">
          <div id="" class="container-fluid ">
            <div id="" class="row ">
              <div id="" class="col ">
                <div class="content">
                  <h1 class="pageHeader">
                    <i class="fas fa-tachometer-alt"></i> Dashboard
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div id="" class="container-fluid ">
            <div id="" class="row ">
              <div id="" class="col ">
                <div class="content">
                  <div class="component">
                    <div id="" class="row ">
                      <div id="" class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 ">
                        <div class="content">
                          <div class="userImage">
                            <a class="notification" href="/user/profile">
                              <img src="https://sonrisas1.s3.amazonaws.com/USERS/60e0afa6f469340b9e6323bc/PICTURE/1630794830712_squareSmile7.png">
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="AccountSummary" class="col matchHeight">
                        <div class="content">
                          <div class="fullHeight overflowRightScroll">
                            <div class="itemHeader">
                              <div class="itemHeaderTextZone">
                                <div class="itemHeaderText withIcon">
                                  <div class="text">Account Summary</div>
                                </div>
                              </div>
                            </div>
                            <div class="sectionValueZone">
                              <div class="sectionValueWrapper">
                                <div class="sectionValue">
                                  <div class="section">Name:</div>
                                  <div class="value">Daniel E. Zaldivar</div>
                                </div>
                              </div>
                              <div class="sectionValueWrapper">
                                <div class="sectionValue">
                                  <div class="section">Phone</div>
                                  <div class="value">(801) 319 - 5789</div>
                                </div>
                              </div>
                              <div class="sectionValueWrapper">
                                <div class="sectionValue">
                                  <div class="section">Email:</div>
                                  <div class="value">danielezaldivar@gmail.com</div>
                                </div>
                              </div>
                              <div class="sectionValueWrapper">
                                <div class="sectionValue">
                                  <div class="section">Treatment Plan:</div>
                                  <div class="value">
                                    <a class="notification" href="/user/treatmentplan">View Treatment Plan</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
          <div id="" class="container-fluid ">
            <div id="" class="row alignerScheduleZone">
              <div id="CurAlignerColumn" class="col col-xl-4 " style="min-width: 250px;">
                <div class="content">
                  <div id="CurAlignerComponent1" class="component curAlignerWrapper">
                    <div class="curAligner">
                      <div class="curAlignerNum">
                        <div class="step">Step</div>
                        <div class="stepNum">12</div>
                      </div>
                      <div class="curAlignerDescWrapper">
                        <div class="curAlignerText">
                          <div class="curAlignerDesc">Current Aligner Step</div>
                          <div class="curAlignerDate">Started: 12/04/22</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="CurAlignerComponent2" class="component">
                    <div class="itemHeader d-flex">
                      <div class="itemHeaderTextZone text-center">
                        <div class="itemHeaderText">Aligner Alert</div>
                        <div class="itemHeaderSubText">When to change to your next aligner.</div>
                      </div>
                    </div>
                    <div class="alignerScheduleNext">
                      <div class="text-center noMoreAligners">TREATMENT FINISHED!</div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="" class="col  alignerScheduleColumn">
                <div class="content">
                  <div id="AlignerScheduleComponent" class="component">
                    <div class="itemHeader d-flex">
                      <div class="calendarIcon">
                        <i class="fas fa-calendar-alt"></i>
                      </div>
                      <div class="itemHeaderTextZone">
                        <div class="itemHeaderText">Aligner Schedule</div>
                        <div class="itemHeaderSubText">View your full aligner schedule.</div>
                      </div>
                    </div>
                    <div class="alignerSchedule">
                      <div class="eventWrapper next">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Dec</div>
                              <div class="eventDateNumber">4</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">
                              <span class="" id="">
                                <span style="font-weight: 600;font-size: 18px;">Start</span>
                              </span> Upper <span style="font-weight: 600;">#12</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pastTitle">History</div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Jul</div>
                              <div class="eventDateNumber">3</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 1</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Jul</div>
                              <div class="eventDateNumber">17</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 2</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Jul</div>
                              <div class="eventDateNumber">31</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 3</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Aug</div>
                              <div class="eventDateNumber">14</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 4</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Aug</div>
                              <div class="eventDateNumber">28</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 5</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Sep</div>
                              <div class="eventDateNumber">11</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 6</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Sep</div>
                              <div class="eventDateNumber">25</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 7</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Oct</div>
                              <div class="eventDateNumber">9</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 8</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Oct</div>
                              <div class="eventDateNumber">23</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 9</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Nov</div>
                              <div class="eventDateNumber">6</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 10</div>
                          </div>
                        </div>
                      </div>
                      <div class="eventWrapper done">
                        <div class="event">
                          <div class="eventDateWrapper">
                            <div class="eventDate">
                              <div class="eventDateMonth">Nov</div>
                              <div class="eventDateNumber">20</div>
                            </div>
                          </div>
                          <div class="eventText">
                            <div class="eventTextTitle">Start Aligner 11</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`

export default SSCONTENT;
export {SSCONTENT};

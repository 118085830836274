//import React from 'react';
const PAGE = [
	{
    "type": "div",
    "content": `<div class="preHeader">LIMITED TIME GIVEAWAY!</div>`
  },//CONTENT 0
  {
    "type": "div",
    "content": `<h1 class="sectionTitle">Enter to win up to <span class="emph">50% off your perfect smile!</span></h1>`
  },//CONTENT 1
  {
    "type": "div",
    "content": `To sign up all you need to do is fill out the following form. By filling out the form you consent to receive messages concerning this giveaway and agree to all conditions found in the <a href="#Rules">rules</a> below. Must be 18 years of older to apply.`
    //CONTENT 2
  },
  {
    "content": `What we're giving away`
    //CONTENT 3
  },
  {
    "type": "span",
    "content": `Sign up for a chance to win up to <span class="emph">50% OFF aligners</span> and more!`
    //CONTENT 4
  },
  {
    "content": `Este sorteo ("sorteos") consistirá en los siguientes premios: 50% de descuento en el tratamiento de Clear Aligners para el 1er lugar, 40% de descuento en los tratamientos de Clear Aligners para el 2do lugar, 30% de descuento en los tratamientos de Clear Aligners para el 3er lugar y un descuento general para todos los participantes no premiados en el 1er, 2do y 3er lugar. Después del sorteo, los ganadores y todos los participantes recibirán una notificación por correo electrónico, mensaje de texto y llamada telefónica con instrucciones sobre cómo canjear o perder sus premios. Sin obligación.`
    //CONTENT 5
  },
  {
    "type": "div",
    "content": `<div class="headerSize3">1st place gets</div><div class="largeText emph">50% OFF!</div>`
    //CONTENT 6
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">This means 1st place winners only pay <span class="emph">$725 USD</span> <strong>FOR THEIR WHOLE TREATMENT!</strong></div>
                <div class="bodySize2">All discounts apply to the already amazing low discounted price of $1,849 USD towards a full Clear Aligners treatment. See below for conditions and restrictions.</div>`
    //CONTENT 7
  },
  {
    "type": "div",
    "content": `<div class="headerSize3">2nd place gets</div><div class="largeText emph">40% OFF!</div>`
    //CONTENT 8
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">This means 2nd place winners only pay <span class="emph">$870 USD</span> <strong>FOR THEIR WHOLE TREATMENT!</strong></div>
                <div class="bodySize2">These winnings cannot be applied to the subscription payment plan. Other terms and conditions may apply see below for conditions and restrictions.</div>`
    //CONTENT 9
  },
  {
    "type": "div",
    "content": `<div class="headerSize3">3rd place gets</div><div class="largeText emph">30% OFF!</div>`
    //CONTENT 10
  },
  {
    "type": "div",
    "content": `<div class="bodySize1 paddingWidth">This means 3rd place winners only pay <span class="emph">$1,015 USD</span> <strong>FOR THEIR WHOLE TREATMENT!</strong></div>
                <div class="bodySize2">Imagine getting that smile you've always wanted for such a low price! Other terms and conditions may apply see below for conditions and restrictions.</div>`
    //CONTENT 11
  },
  {
    "content": `But that's not all!`
    //CONTENT 12
  },
  {
    "content": `Other prizes`
    //CONTENT 13
  },
  {
    "type": "div",
    "content": `<p>Receive $150 off the already discounted price of $1,849, just for participating!</p>
          <p>After the drawing, winners and all participants will receive an email notification with instructions on how to redeem or forfeit their prizes. No obligation.</p>
          <p>You can proceed to redeem this offer without waiting. Please send us an email to <a href="mailto:support@Smiles.club">support@Smiles.club</a> with your name and telephone number and one of our agents will contact you with instructions on how to proceed.</p>
          <p>If you are awarded one of the top three prices, Smiles Club will reimburse you the difference in price.</p>`
    //CONTENT 14
  },
  {
    "content": `Join Now`
    //CONTENT 15
  },
  {
    "content": `See Rules`
    //CONTENT 16
  },
  {
    "type": "span",
    "content": `Aligners Cost <strong>TOO MUCH!</strong>`
    //CONTENT 17
  },
  {
    "content": `Let us Help!`
    //CONTENT 18
  },
  {
    "content": `Traditional Aligners`
    //CONTENT 19
  },
  {
    "content": `INVISALIGN`
    //CONTENT 20
  },
  {
    "content": `Other Companies`
    //CONTENT 21
  },
  {
    "content": `Our Base Price`
    //CONTENT 22
  },
  {
    "content": `Up to`
    //CONTENT 23
  },
  {
    "content": `Who are we?`
    //CONTENT 24
  },
  {
    "content": `We're a dental lab who's sick of the high prices!`
    //CONTENT 25
  },
  {
    "type": "div",
    "content": `<p>We have been providing dentists with high-quality aligners and other dental artifacts for years. We have noticed the amount of markup that comes with clear aligners. As parents who had to pay those high prices for clear aligners, we understand the cost of children.</p>
      <p>So we created this company to see if we can help other parents save some money. Let us provide you with high-quality aligners for your children at affordable prices.</p>`
    //CONTENT 26
  },
  {
    "content": `Join Now`
    //CONTENT 27
  },
  {
    "content": `Full rules and conditions`
    //CONTENT 28
  },
  {
    "content": `Business Opportunities`
    //CONTENT 31
  },
  {
    "content": `Join our Team`
    //CONTENT 32
  },
  {
    "type": "div",
    "content": `
      <p>If you are interested in an opportunity working with us selling clear aligners, we'd like to hear from you. We're looking for salespeople who are motivated and friendly. Flexible hours and great growth opportunities available.</p>
      <p>If you have any other business question we would LOVE to help you out!</p>
    `
    //CONTENT 33
  },
  {
    "content": `Contact Us`
    //CONTENT 34
  },
  {
    "type": "div",
    "content": `
      <div id="SuccessMessage" class="animated fadeIn message">
        <div class="messageText">
          <div>
            <div class="icon"><i class="fas fa-laugh-beam"></i></div>
            <h2>
              You've been entered to our giveaway!
            </h2>
            <div class="text1">
              Your <strong>$150</strong> off coupon code is
            </div>
            <div class="text2">
              <strong>[GIVEAWAY_150_OFF_202201]</strong>
            </div>
            <div class="text3">
              <p>This is valid towards your purchase of any one of our full aligner treatment plans at full price. Coupon expires April/01/2022. We will contact winners on their provided contact information. See Official Rules below for more details. Contact us if you have any questions.</p>
            </div>
          </div>
        </div>
      </div>
    `
    //CONTENT 35
    //Form JOIN Success Message
  },
  {
    "content": `This giveaway has finished. Stay tuned for our future giveaways.`
    //CONTENT 36
  },
];




	
export default PAGE;
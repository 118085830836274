//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Purchase your Full Clear Aligners Kit Here! No risk invisible aligners",
			"DESCRIPTION": "Smiles Club provides easy beautiful teeth straightening from home. Purchase invisible orthodontics without BPA here.",
			"KEYWORDS": "Teeth straightening at home, affordable, clear braces, invisible braces, best aligners, safest aligners, number one clear aligners, invisible aligners, BPA, financial options, safe plastics, discreetly, Braces alternative"
		},
		"CONTENT": {
			"BreadCrumbs": [
				{
					"content": `Home` //BREADCRUMB NAV NAMES
				},
				{
					"content": `Blog` //BREADCRUMB NAV NAMES
				}
			],
			"Loading": [
				{
					"content": "Updating"
				},
				{
					"content": ""
				}
			],
			"BlogZone": [
				{
					"content": `Date`
				},
				{
					"content": `Categories`
				},
				{
					"content": `Keywords`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | ¡Adquiere aquí tu kit completo de alineadores transparentes! Alineadores invisibles sin riesgo",
			"DESCRIPTION": "Sonrisas Club proporciona un enderezamiento fácil y hermoso de los dientes desde casa. Compra ortodoncia invisible sin BPA aquí.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"BreadCrumbs": [
				{
					"content": `Casa` //BREADCRUMB NAV NAMES
				},
				{
					"content": `Blog` //BREADCRUMB NAV NAMES
				}
			],
			"Loading": [
				{
					"content": "Actualizando"
				},
				{
					"content": ""
				}
			],
			"BlogZone": [
				{
					"content": `Fecha`
				},
				{
					"content": `Categorías`
				},
				{
					"content": `Palabras clave`
				}
			]
		}
	}
};


	
export default PAGE;

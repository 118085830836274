import React from 'react';
import API_CALLS from 'API_CALLS/index';
import PAGES from 'website/pages/pages_FULL.js';

const DangerSet = ({newValue, className, id, type}) => {
  function createMarkup(newValue) {
    return {__html: newValue};
  };
  var TagName = type;
  return ( <TagName dangerouslySetInnerHTML={createMarkup(newValue)} className={className} id={id}/> ) 
}

const GetCurrentPage = () => {
  const PAGES_LIST = PAGES(window.Session.Language);
  var pathname = window.location.pathname.toLowerCase();
  if(pathname === "/"){ //IT'S THE HOME PAGE STOP HERE
    return PAGES_LIST.filter(obj=>obj.PageName === "Home")[0]; //THIS SHOULD BE HOME OBJECT 
  }
  var currentPage = {};
  for(var pageObj of PAGES_LIST){
    for(var pathExact of pageObj.Paths){
      if(pathname === pathExact) currentPage = pageObj; //If perfect match stop here
    }
  }
  if(!currentPage || !currentPage.PageName){
    var pathNameArray = pathname.split("/").slice(1).map(obj=>`${obj[0].toUpperCase()}${obj.slice(1)}`); //WE REMOVE THE FIRST VALUE BECAUSE IT'LL BE ""
    currentPage = {
      "PageName": pathNameArray.join(" -> "),
      "Name": pathNameArray.slice(-1)[0],
      "Paths": [pathname]
    };
  }
  return currentPage;
}

const GetBasicUserInfoAsync = async ({noCache=false, noGlobalUtilLogin=false}) => {
  if(typeof window === 'undefined') return {"ERROR": "WINDOW_NOT_FOUND"}; //DONT RUN FOR REACT SNAP
  if(window.LoadingBasicUserInfoAsync) return {"ERROR": "CURRENTLY_LOADING"}; //IF UPDATED A FEW SECONDS AGO, THEN DON'T RUN AGAIN
  if(window.BasicUserInfoAsyncLastRan && ((window.BasicUserInfoAsyncLastRan - Date.now())<1000)) return {"ERROR": "LOADED_RECENTLY"}; 
  window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js GetBasicUserInfoAsync()`, NAME:"GetBasicUserInfoAsync"});
  window.LoadingBasicUserInfoAsync = true;

  //START API
  return await window.Client.query({
    query: API_CALLS.USERS.QUERIES.me(),
    fetchPolicy: (noCache ? "no-cache" : "cache")
  })
  .then(({data={}}) => {
    window.LoadingBasicUserInfoAsync = false;
    window.BasicUserInfoAsyncLastRan = Date.now();


    var me = window.GlobalUtil.APICleanObj(data.me);  
    var loginObj = {token: window.Session.token, user: me};          
    if(!noGlobalUtilLogin) window.GlobalUtil.login(loginObj, true);
    return loginObj;
  })
  .catch((error)=>{
    window.LoadingBasicUserInfoAsync = false
    window.BasicUserInfoAsyncLastRan = null;
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js GetBasicUserInfoAsync()`, NAME:"GetBasicUserInfoAsync error", CONTENT:[error]});
    //console.dir("GetBasicUserInfoAsync error", error);
    window.GlobalUtil.logout(false);
    return {"ERROR": "QUERY_FAILED"};
  })
}



const GetFullUserInfo = ({noCache=false, callBackSuccess=()=>{}, callBackFailed=()=>{}}) => {
  if(typeof window === 'undefined') return true; //DONT RUN FOR REACT SNAP
  if(window.LoadingGetFullUserInfo) return true; //IF UPDATED A FEW SECONDS AGO, THEN DON'T RUN AGAIN
  if(window.GetFullUserInfoLastRan && ((window.GetFullUserInfoLastRan - Date.now())<1000)) return; 
  window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js GetFullUserInfo()`, NAME:"GetFullUserInfo"});
  window.LoadingGetFullUserInfo = true;
  //START API
  window.Client.query({
    query: API_CALLS.USERS.QUERIES.userFull(),
    fetchPolicy: (noCache ? "no-cache" : "cache")
  })
  .then(({data={}}) => {    
    window.LoadingGetFullUserInfo = false;   
    window.GetFullUserInfoLastRan = Date.now();   


    var userFull = window.GlobalUtil.APICleanObj(data.userFull);                     
    var loginObj = {token: window.Session.token, user: userFull};                
    window.GlobalUtil.login(loginObj, true);
    callBackSuccess(loginObj);
  })
  .catch((error)=>{
    window.LoadingGetFullUserInfo = false
    window.GetFullUserInfoLastRan = null;


    console.dir("GetFullUserInfo error", error);
    console.log('error', error);
    
    //window.GlobalUtil.logout(false);
    callBackFailed();
  })
}


const UpdateUserInfo = async (VARIABLES) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(typeof window === 'undefined') return null;           
    var variables = window.GlobalUtil.APISubmitObjCleaner(API_CALLS.USERS.CLEAN_FIELDS, VARIABLES);          
    var newUser = await window.Client.mutate({
        mutation: API_CALLS.USERS.MUTATIONS.update(),
        variables: variables
      })
      .then(result => {              
        return window.GlobalUtil.APICleanObj(result.data.updateUser);
      })
      .catch((error)=>{
        console.log("UpdateUserInfo FAIL catch", error);
        throw error;
      });
    return newUser;
  }
  return null;
}



//IF USER IS LOGGED IN CHECK TO SEE IF ANY NEW GLOBAL VAR EXIST FOR THIS USER. IF THEY DO ADD THEN TO THE USER.
const UpdateUserWithAnyGlobalVars = async (curObj={}) => {
  window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js UpdateUserWithAnyGlobalVars()`, NAME:"UpdateUserWithAnyGlobalVars"});
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(typeof window === 'undefined') return null; 
    if(!window.Session || !window.Session.user || !window.Session.user._id) return null; 
    var newUserVarObj = window.GlobalUtil.getGlobalVariable("NEW_USER_VAR", {});
    curObj = {id: window.Session.user._id, ...curObj};

    //IF USER VAR ALREADY EXIST THEN SKIP THEM, ELSE ADD THEM
    if(!window.Session.user.website) curObj.website = window.SiteSettings.siteNameAllCaps;
    
    if(Object.keys(curObj).length > 1) { //CHECK HERE TO NOT UPDATE THE USER IF NOTHING TO UPDATE
      var newUser = await UpdateUserInfo(curObj); //THIS IS ASYNC BUT CAN BE IGNORED
      await window.GlobalUtil.deleteGlobalVariable("NEW_USER_VAR"); //DELETE THE USER GLOBAL VARS  
      window.GlobalUtil.consoleLogNew({CONTENT:[newUser]});
      console.log(`newUser`,"\n\n",newUser,"\n\n");
            
      return newUser;
    } else {
      window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js UpdateUserWithAnyGlobalVars()`, NAME:`UpdateUserWithAnyGlobalVars DIDN'T UPDATE ANYTHING`});
    }
  }
  return null;
}






const GetSiteSettingsFunction = ({forceUpdate=false, callBack=()=>{}, triggerEvent=true}) => {        
  var SiteSettings = window.GlobalUtil.LocalStorage.get("SiteSettings");
  // const CreateLogoObj = (cleanObj)=>{
  //   cleanObj.logosObj = {};
  //   cleanObj.logos.map((logoObj, index)=>{
  //     cleanObj.logosObj[logoObj.name] = logoObj;
  //   })
  //   return cleanObj;
  // }         

  if(SiteSettings && !forceUpdate){
    var cleanObj = JSON.parse(SiteSettings);
    if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", cleanObj);
    callBack(cleanObj);
  } else {
    if(window.SiteSettingsLoading) return;
    window.SiteSettingsLoading = true;
    window.Client.query({
      query: API_CALLS.SITE_SETTINGS.QUERIES.single(),
      variables: {id: "604d1cee8015f32ea407590d"},
      fetchPolicy: (!forceUpdate ? "cache" : "no-cache")
    })
    .then(result => {
      window.SiteSettingsLoading = false;
      var cleanObj = window.GlobalUtil.APICleanObj(result.data.siteSetting);                            
      //cleanObj = CreateLogoObj(cleanObj); //CREATE LOGO OBJ TO FOR EASE OF LOGO GET
      var ExpDate = new Date();
      ExpDate.addTimeFrame(1,"DAY");          
      window.GlobalUtil.LocalStorage.set("SiteSettings", JSON.stringify(cleanObj), ExpDate); 
      if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", cleanObj);  
      callBack(cleanObj);
    })
    .catch((error)=>{
      window.SiteSettingsLoading = false;
      window.SiteSettings = {
        ...window.GlobalUtil.BasicSiteSettings
      };
      if(triggerEvent) window.GlobalUtil.triggerEvent("SiteSettingsEvent", window.SiteSettings); 
      console.log("GetSiteSettings failed", error);
    })//END OF catch
  }
}



const GetSiteSettings = ({forceUpdate=false, callBack=()=>{}, triggerEvent=true}) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client) GetSiteSettingsFunction({forceUpdate, callBack, triggerEvent})
  else{
    window.GlobalUtil.subscribeEventOnce("ClientEvent", readyToGo=>{
      GetSiteSettingsFunction({forceUpdate, callBack, triggerEvent})
    });
  }
}




const UpdateSiteSettings = ({newObj={}, onSuccess=()=>{}, onFail=()=>{}, failPathString="",}) => {
  //ONLY RUN ONCE CLIENT IS READY
  if(window.Client){
    if(typeof window === 'undefined') return null;
    var cleanObj = {...newObj, id: window.SiteSettings._id};   
    cleanObj = window.GlobalUtil.stripOutFromObj(cleanObj, "__typename");   
    window.Client.mutate({
      mutation: API_CALLS.SITE_SETTINGS.MUTATIONS.update(),
      variables: cleanObj
    })
    .then(result => {
      GetSiteSettings({
        forceUpdate: true, 
        callBack: onSuccess, 
        //triggerEvent: true
      });
    })
    .catch((error)=>{
      console.log(failPathString ? failPathString : "helper-functions -> UpdateSiteSettings -> API_CALLS.SITE_SETTINGS.MUTATIONS.update FAIL catch", error);
      onFail();
    })
  }
  else return;
}





const LoginWith3rdPary = ({token, type, autoCreate=false, callBackSuccess, callBackFailed}) => {
  if(typeof window === 'undefined') return true; //DONT RUN FOR REACT SNAP
  window.Client.query({
    query: API_CALLS.USERS.QUERIES.login3rdParty(),
    variables: {
      token,
      type,
      autoCreate: autoCreate,
      website: window.SiteSettings.siteNameAllCaps
    },
    fetchPolicy: "no-cache"
  })
  .then(({data={}}) => {          
    console.log(`data`,"\n\n",data,"\n\n");
    var loginObj = window.GlobalUtil.APICleanObj(data.login3rdParty);              
    //console.log(`loginObj`,"\n\n",loginObj,"\n\n");
    if(callBackSuccess) callBackSuccess(loginObj);
    else window.GlobalUtil.login(loginObj);
  })
  .catch((error)=>{
    console.dir("LoginWith3rdPary error", error);
    if(callBackFailed) callBackFailed();
  })
}





const GetCouponFromCode = ({VARIABLES}) => {
  if(typeof window === 'undefined') return true; //DONT RUN FOR REACT SNAP
  return window.Client.query({
    query: API_CALLS.COUPONS.QUERIES.couponFinder(),
    variables: VARIABLES
  })
  .then((obj) => {
    return window.GlobalUtil.deepGetFromString(obj, "data,couponFinder", null);
  })
  .catch((error)=>{
    console.log("on Find Coupon catch", error);
  });
}



const AddOrderToUser = async ({paymentOrderID, paymentType, curOrders, subscriptionID, UID, onSuccess=()=>{}, onFail=()=>{}}) => {
  var today = new Date();
  var todayTime = today.getTime();
  //var curOrders = window.GlobalUtil.State.get("newOrder");
  var {products=[], coupon} = curOrders;
  const CHECKOUT_TOTAL = window.GlobalUtil.checkoutCalculatorNew(products, coupon);
  

  var orderItems = await products.map((product)=>{ //THIS WILL BE AN ARRAY OF ORDER ITEM OBJECTS TO MAKE
    var APPROVED = "TRUE";
    if(coupon && (coupon.category === "PROMO") && (product.category === "TREATMENT_PLANS")) APPROVED = "PENDING"; //ONLY PUT APPROVED AS FALSE IF THEY USED A PROMO COUPON CODE AND THEY ARE BUYING A TREATMENT PLAN. IF THEY DIDN'T USE A PROMO CODE WHO CARES.
    var orderItem = {
      //UID, //THESE WILL BE ADDED ON THE SERVER SIDE AFTER THE ORDER IS MADE
      //OID, //THESE WILL BE ADDED ON THE SERVER SIDE AFTER THE ORDER IS MADE
      "name": product.ref,
      "ship": (product.category === "TREATMENT_PLANS" ? false : true),
      "PROMO": { //THIS WILL REPLACE THE REF CODE. WHEN THEY BUY 
        "CODE": ((coupon && coupon.PROMO && coupon.PROMO.CODE) ? coupon.PROMO.CODE : null),
        "UID": ((coupon && coupon.PROMO && coupon.PROMO.UID) ? coupon.PROMO.UID : null), //ID OF PERSON WE'RE GOING TO PAY
        "CPID": ((coupon && coupon._id) ? coupon._id : null), //ID OF COUPON USED
        "DATE": `${todayTime}`,
        "EARNED": ((coupon && coupon.commission) ? coupon.commission : null), //HOW MUCH THE AFFILIATE GETS FOR EACH SALE
        "APPROVED": APPROVED, //YOU CAN PAY THE AFFILIATE RIGHT AWAY UNLESS IT'S A TREATMENT PLAN. THEN WE NEED TO APPROVE IT BEFORE WE PAY THE AFFILIATE.
        "ADMIN_PAID": false,
        "ADMIN_PAID_DATE": "",
        "ADMIN_CHECK_NUMBER": ""
      },
      "product": {
        "ID": product.ID,
        "ref": product.ref,
        "type": product.type,
        "name": product.name,
        "quantity": product.quantity,
        "description": product.description,
        "cost": product.cost,
        "shipping": product.shipping,
        "discount": product.discount,
        "language": product.language,
        "website": product.website,
        "category": product.category
      }
    }
    // if(product.subscription) {
    //   orderItem.product.subscription = {
    //     setupFee: product.setupFee, //COST CHARGED ONCE FOR SETUP. THIS WILL BE CHARGED WITH FIRST MONTH CHARGE RIGHT AWAY
    //     billingCycleCharge: product.billingCycleCharge, //HOW MUCH WE CHARGE EACH BILLING CYCLE
    //     paypalID: product.paypalID, //IF THIS EXSIT THEN USE IT TO FIND PRODUCT ON PAYPAL WHEN CHECKING OUT
    //     billingCycle: product.billingCycle //HOW OFTEN THEY ARE CHARGED. EVERY 1 MONTH, EVERY 2 MONTHS
    //   }
    //}
    return orderItem;
  }); 

  var variables = {
    UID: UID,
    couponID: (coupon ? coupon._id : null),
    orderItemsStringArray: JSON.stringify(orderItems),
    paymentOrderID: paymentOrderID,
    paymentType: paymentType, 
    status: "ACTIVE",
    productsStringArray: JSON.stringify(products),
    cost: {
      subtotal: (CHECKOUT_TOTAL.subtotal ? Number(Number(CHECKOUT_TOTAL.subtotal).toFixed(2)) : 0), //TOTAL PRICE BEFORE THE DISCOUNTS AND TAXES AND SHIPPING ETC.
      discount: (CHECKOUT_TOTAL.discount ? Number(Number(CHECKOUT_TOTAL.discount).toFixed(2)) : 0), //TOTAL DISCOUNT, INCLUDING COUPONS AND PRODUCT PRICE REDUCTION.
      subtotalMinusDis: (CHECKOUT_TOTAL.subtotalMinusDis ? Number(Number(CHECKOUT_TOTAL.subtotalMinusDis).toFixed(2)) : 0),
      tax: (CHECKOUT_TOTAL.tax ? Number(Number(CHECKOUT_TOTAL.tax).toFixed(2)) : 0),
      setupFee: 0,
      shipping: (CHECKOUT_TOTAL.shipping ? Number(Number(CHECKOUT_TOTAL.shipping).toFixed(2)) : 0),
      total: (CHECKOUT_TOTAL.total ? Number(Number(CHECKOUT_TOTAL.total).toFixed(2)) : 0)
    },
    website: window.SiteSettings.siteNameAllCaps,
    paidAt: `${todayTime}`,
    chargingAt: `${todayTime}`,
    sendEmail: true
  }
  if(subscriptionID) variables.paymentSubscriptionID = subscriptionID;
  // var variables = {
  //   UID: "5f9dbef56dd69d3477b3787d",
  //   chargingAt: "1614725393658",
  //   couponID: "603813df1c0396323ec0070e",
  //   discounts: 12.15,
  //   paidAt: "1614725393658",
  //   paymentOrderID: "32K18727DW111204Y",
  //   products: [
  //     {
  //       costWithDiscount: "80.99",
  //       productID: "5fb063bdddcf613ba677e534",
  //       quantity: "1"

  //     }
  //   ],
  //   shipping: 0.00,
  //   status: "ACTIVE",
  //   subTotalWithDis: 68.8415,
  //   tax: 3.442,
  //   total: 72.28
  // }

  
  
  return await window.Client.mutate({
    mutation: API_CALLS.ORDERS.MUTATIONS.create(),
    variables: variables
  })
  .then((obj) => {
    window.GlobalUtil.consoleLogNew({LOCATION:`src/global/utils/helper-functions.js -> AddOrderToUser()`, NAME:"Success obj", CONTENT:[obj]});
    onSuccess(obj.data.createOrder)
  })
  .catch((error)=>{
    console.log("src/global/utils/helper-functions.js -> AddOrderToUser() Failed", error);
    onFail()
  });
}



export {
  DangerSet,
  GetBasicUserInfoAsync,
  GetFullUserInfo,
  UpdateUserInfo,
  UpdateUserWithAnyGlobalVars,
  GetSiteSettings,
  UpdateSiteSettings,
  LoginWith3rdPary,
  GetCouponFromCode,
  GetCurrentPage,
  AddOrderToUser
}




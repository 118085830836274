import React from 'react';
import {CartContent} from 'global/components/Cart/index';
//import Loading from 'global/components/Loading/index';


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      marginTop: 0,
      withTooWide: undefined
    };    
    
  }

  componentDidMount(){
    this.reizeListener = window.addEventListener("resize", this.windowResized);
    this.windowResized();
  }

  componentWillUnmount(){
    if(this.windowResizedTimemout) clearTimeout(this.windowResizedTimemout)
    window.removeEventListener("resize", this.reizeListener);
  }

  windowResized = (e) => {    
    var {withTooWide, active} = this.state;  
    var curHeight = window.innerHeight;
    var curWidth = window.innerWidth;

    clearTimeout(this.windowResizedTimemout)
    this.windowResizedTimemout = setTimeout(() => {
       var curWidth = window.innerWidth;
      if(curWidth > 991) {
        this.setState({ //THIS WILL FORCE IT TO EXPAND
          withTooWide: true,
          marginTop: `0px`,
          active: false 
        });
      } else {
        if(withTooWide){ //IF THE SCREEN WAS TOO BIG, BUT NOW IT'S NOT, THEN SHRINK THE CHECKOUT
          this.toggleHeight(true);
          this.setState({
            withTooWide: false
          });
        } else { //IF THE SCREEN DIDN'T CHANGE FROM TOO BIG TO TOO SMALL THEN JUST KEEP THE SAME
          if(withTooWide === undefined) this.toggleHeight(true);
          else this.toggleHeight(active);
        }; //THIS WILL FORCE IT TO SHRINK
      }
    }, 100);
  }

  toggleHeight = (forceActive) => { //TRUE WILL FORCE IT TO SHRINK, WHILE FALSE WILL FORCE IT TO EXPAND, WHILE NOTHING WILL HAVE IT TOGGLE
    var {active} = this.state;  
    if((forceActive === true) || (forceActive === false)) active = !forceActive;
    if(this.CartContentWrapperRef){
      var currentHeight = this.CartContentWrapperRef.clientHeight;
      this.setState({
        marginTop: ((active === false) ? `-${currentHeight - 60}px` : `-10px`),
        active: !active //IF keepSame IS TRUE, THEN IT'S RESIZED AND JUST NEEDS TO CHANGE HEIGHT NOT RESET
      });
    } 
  }

  render(){
    var {newOrder} = this.props;  
    var {active, marginTop} = this.state;  
    
    return (
      <div id="CheckoutPageComponentLeftSide" className={`${active ? 'active' : ''}`}>
        <div id="CartContentWrapper" ref={e=>this.CartContentWrapperRef=e} style={{"marginTop": marginTop}}>
          <CartContent newOrder={newOrder}/>
          <div className="expandButton d-sm-block d-lg-none">
            <button className="button button1" onClick={this.toggleHeight}>
              {active ? "Expand" : "Shrink"}&nbsp;&nbsp;
              <i className={`fas fa-angle-double-${active ? 'down' : 'up'}`}></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}








export default Index;
      

import React from 'react';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ending: {},
      countdownTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    };

    var ExpirationDate = new Date();
    ExpirationDate = new Date(ExpirationDate.getStartOfMonth());
    ExpirationDate.setMonth(ExpirationDate.getMonth()+1);
    this.state.ending = new Date(ExpirationDate.getTime())
  }

  componentDidMount(){
    this.GetCountdownTime();
  }

  componentWillUnmount(){
    clearTimeout(this.GetCountdownTimeRef);
  }

  GetCountdownTime(){          
    var countdown = this.state.ending.getDateCountDown();
    var {countdownTime} = this.state;
    var changedZone = {};
    if(countdown.slice(0,2) !== countdownTime.days) changedZone["0"] = true;
    if(countdown.slice(3,5) !== countdownTime.hours) changedZone["1"] = true;
    if(countdown.slice(6,8) !== countdownTime.minutes) changedZone["2"] = true;
    this.setState({
      countdownTime: {
        days: countdown.slice(0,2),
        hours: countdown.slice(3,5),
        minutes: countdown.slice(6,8),
        seconds: countdown.slice(9),
      },
      changedZone: changedZone
    }, ()=>{
      this.GetCountdownTimeRef = setTimeout(()=>{
        this.setState({changedZone: {}})
        this.GetCountdownTime();
      }, 1000);
    });
  }

  render(){
    //var {showNav, offtop, HeaderStyles={}} = this.state;
    var {countdownTime, changedZone={}} = this.state;
    return (
      <div className="countdownCards">
        <div className={`cardZone ${changedZone["0"] ? 'redBackground' : ''}`}>
          <div className="card">
            <div className="number">{countdownTime.days}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            Days
          </div>
        </div>
        <div className={`cardZone ${changedZone["1"] ? 'redBackground' : ''}`}>
          <div className="card">
            <div className="number">{countdownTime.hours}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            Hrs
          </div>
        </div>
        <div className={`cardZone ${changedZone["2"] ? 'redBackground' : ''}`}>
          <div className="card">
            <div className="number">{countdownTime.minutes}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            Mins
          </div>
        </div>
        <div className={`cardZone ${changedZone["3"] ? 'redBackground' : ''}`}>
          <div className="card">
            <div className="number">{countdownTime.seconds}</div>
            <div className="line"></div>
          </div>
          <div className="title">
            Secs
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
      
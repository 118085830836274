import React from 'react';
import {SSCONTENT as Home} from './Home.js';
import {SSCONTENT as GetStarted} from './GetStarted.js';
import {SSCONTENT as BestAligners} from './BestAligners.js';
import {SSCONTENT as Pricing} from './Pricing.js';
import {SSCONTENT as Blog} from './Blog.js';
import {SSCONTENT as BlogAreClearAlignersBetterThanBraces} from './BlogAreClearAlignersBetterThanBraces.js';
import {SSCONTENT as BlogHowToTakeAGreatClearAlignerImpression} from './BlogHowToTakeAGreatClearAlignerImpression.js';
import {SSCONTENT as BlogDoAlignersCloseGaps} from './BlogDoAlignersCloseGaps.js';
import {SSCONTENT as BlogClearAlignersVsInvisibleAligners} from './BlogClearAlignersVsInvisibleAligners.js';
import {SSCONTENT as BlogHiddenFeesInMostClearAligners} from './BlogHiddenFeesInMostClearAligners.js';
import {SSCONTENT as BlogHowInvisibleAlignersWork} from './BlogHowInvisibleAlignersWork.js';
import {SSCONTENT as BlogAreClearAlignersTheRightFitForYou} from './BlogAreClearAlignersTheRightFitForYou.js';
import {SSCONTENT as Blog10ReasonsWhyEveryoneShouldBeUsingClearAligners} from './Blog10ReasonsWhyEveryoneShouldBeUsingClearAligners.js';
import {SSCONTENT as Blog10MisconceptionsPeopleHaveAboutClearAligners} from './Blog10MisconceptionsPeopleHaveAboutClearAligners.js';
import {SSCONTENT as Login} from './Login.js';
import {SSCONTENT as ImpressionKit} from './ImpressionKit.js';
import {SSCONTENT as Faq} from './Faq.js';
import {SSCONTENT as About} from './About.js';
import {SSCONTENT as Contact} from './Contact.js';
import {SSCONTENT as Consent} from './Consent.js';
import {SSCONTENT as Privacy} from './Privacy.js';
import {SSCONTENT as Terms} from './Terms.js';
import {SSCONTENT as Guarantees} from './Guarantees.js';
import {SSCONTENT as Promo} from './Promo.js';
import {SSCONTENT as BuyNow} from './BuyNow.js';
import {SSCONTENT as CustomLandingPage1} from './CustomLandingPage1.js';
import {SSCONTENT as CustomLandingPage2} from './CustomLandingPage2.js';
import {SSCONTENT as CustomLandingPage3} from './CustomLandingPage3.js';
const SS_CONTENT_STRING = {
"/":Home,
"/getstarted":GetStarted,
"/bestaligners":BestAligners,
"/pricing":Pricing,
"/blog":Blog,
"/blog/are-clear-aligners-better-than-braces":BlogAreClearAlignersBetterThanBraces,
"/blog/how-to-take-a-great-clear-aligner-impression":BlogHowToTakeAGreatClearAlignerImpression,
"/blog/do-aligners-close-gaps":BlogDoAlignersCloseGaps,
"/blog/clear-aligners-vs-invisible-aligners":BlogClearAlignersVsInvisibleAligners,
"/blog/hidden-fees-in-most-clear-aligners":BlogHiddenFeesInMostClearAligners,
"/blog/how-invisible-aligners-work":BlogHowInvisibleAlignersWork,
"/blog/are-clear-aligners-the-right-fit-for-you":BlogAreClearAlignersTheRightFitForYou,
"/blog/10-reasons-why-everyone-should-be-using-clear-aligners":Blog10ReasonsWhyEveryoneShouldBeUsingClearAligners,
"/blog/10-misconceptions-people-have-about-clear-aligners":Blog10MisconceptionsPeopleHaveAboutClearAligners,
"/login":Login,
"/impressionkit":ImpressionKit,
"/faq":Faq,
"/about":About,
"/contact":Contact,
"/consent":Consent,
"/privacy":Privacy,
"/termsofuse":Terms,
"/guarantees":Guarantees,
"/promo":Promo,
"/buy":BuyNow,
"/deals001":CustomLandingPage1,
"/deals002":CustomLandingPage2,
"/deals003":CustomLandingPage3,
};
 export default SS_CONTENT_STRING;
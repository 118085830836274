//import React from 'react';
const PAGE = {
  	"ENGLISH": {
		"HEADER": {
			"BASEURL": "https://smiles.club",
			"TITLE": "Smiles Club | Clear aligners made of premium materials without harmful toxins",
			"DESCRIPTION": "Which clear aligners are the best? Smiles Club's! Safest USA made clear aligners with no hidden fees. Risk free orthodontics.",
			"KEYWORDS": "Best in Clear Aligners, Teeth Straightening Treatments, Smiles.Club, Smiles Club, clear treatment, state-of-the-art Clear Braces, risk free experience, flexible payment options, compare aligners, price compare"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">HIGH QUALITY LOW PRICES</div>
						<h1 class="sectionTitle">Discover why we are the BEST Clear Aligner Company with the Best Prices!</h1>
						<div class="">
							<p>Our team of experienced dental professionals ensures that each treatment plan is tailored to meet individual needs, guaranteeing effective and safe teeth straightening results. Smiles Club's dedication to customer satisfaction is evident throughout the process.</p>
							<p>Smiles Club stands out as the clear choice when it comes to clear aligner companies, offering a superior experience with the best price and service in the market. Unlike other companies, Smiles Club prioritizes affordability, ensuring that their clear aligner prices are competitive without compromising on quality. With Smiles Club, you can achieve a confident smile without breaking the bank. </p>
						</div>
					`
				}
			],
			"TopMainSectionImage1": [
				{
					"alt": `Women with invisible aligner`
				}
			],
			"Compare": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">COMPARE FOR YOURSELF</div>
						<h2 class="sectionTitle2 limitWidth">Top quality aligners at a fraction of the price</h2>
						<div class="subHeader" style="max-width: 600px; margin: auto;">With Smiles Club you can manage your budget and prioritize your oral health without compromising on quality care. You do not have to spend a fortune to get a perfect smile </div>
					`
				},
				{
					"type": "span",
					"content": `Other Aligners`
				},
				{
					"type": "span",
					"content": `Traditional Braces`
				},
				{
					"content": [
						["Quality","High","Varies","Varies"],
						["Clinic visits","No ","No","Yes"],
						["Interest","None","Medium","High"],
						["Financing Term","None","0 - 24 mos","0 - 60 mos"],
						["Debt","None","Medium","High"],
						["Collections","None","Yes","Yes"],
						["Glue Attachments","No","No","Yes"],
						["Discreet","Yes","Yes","No"],
						["Cost","Avg. $1,849","Avg. $3,500","Avg.$5,500"],
						["Cost of retainers","$75","avg. $225","avg. $300"],
						["Made in the USA","Yes","Varies","Varies"],
					]
				},
				{
					"content": `Mother and daughter looking at teeth straightening kits online`
				}
			],
			"FullSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">WE FOCUS ON QUALITY</div>
						<h2 class="sectionTitle2 limitWidth">Our commitment ensures a positive experience throughout your clear treatment.</h2>
						<div class="subHeader">Experience the difference and choose Smiles Club for affordable clear aligners without compromising on quality or service.</div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3 class="sectionTitle3">Get state-of-the-art Clear Braces!</h3>
						<div class="text">
							<div class="subHeaderBasic">Say goodbye to traditional braces, and hello to our discreet and convenient solution for your orthodontic needs.</div>
							<br/>
							<div>Embrace the future of teeth straightening with us Smiles Club, the trusted leaders in clear Orthodontics and invisible Orthodontics. With clear aligner technology, the road to straighter teeth is more accessible and comfortable than ever before.</div>
						</div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3 class="sectionTitle3">A risk free experience</h3>
						<div class="text">
							<div class="subHeaderBasic">If for whatever reason you are NOT a good candidate, <br/><strong>WE’LL REFUND THE WHOLE COST!</strong></div>
							<br/>
							<div>At our dental clinic, we understand the importance of providing convenient and flexible payment options for our valued patients. It's that risk free policy, with paypal financing options, that convinces so many people to work with us. Join our satisfied customers and start your journey towards dental alignment and straight teeth effortlessly!</div>
						</div>
					`
				}
			],
			"FullStepsButtons": [
				{
					"type": "span",
					"content": `Buy Now`
				},
				{
					"type": "span",
					"content": `Learn More`
				},
				{
					"content": `/pricing`
				}
			],
			"FullStepsImages": [
				{
					"alt": `A young white woman taking a selfie on her smart phone.`
				},
				{
					"alt": `A young black woman taking a selfie on her smart phone.`
				}
			]
		}
	},
	"ESPAÑOL": {
		"HEADER": {
			"BASEURL": "https://sonrisas.club",
			"TITLE": "Sonrisas Club | Alineadores transparentes de materiales de primera calidad sin toxinas dañinas",
			"DESCRIPTION": "¿Qué alineadores transparentes son los mejores? ¡Club Sonrisas! Safest USA fabricó alineadores transparentes sin tarifas ocultas. Ortodoncia sin riesgos.",
			"KEYWORDS": "Alisado de dientes en casa, asequible, frenos transparentes, frenos invisibles, los mejores alineadores, los alineadores más seguros, los alineadores transparentes número uno, los alineadores invisibles, BPA, opciones financieras, plásticos seguros, discretamente, alternativa de frenos"
		},
		"CONTENT": {
			"TopMainSection": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">ALTA CALIDAD PRECIOS BAJOS</div>
						<h1 class="sectionTitle">¡Descubra por qué somos la MEJOR empresa de alineadores transparentes con los mejores precios!</h1>
						<div clase="">
						<p>Nuestro equipo de profesionales dentales experimentados garantiza que cada plan de tratamiento se adapte a las necesidades individuales, garantizando resultados de enderezado dental efectivos y seguros. La dedicación de Smiles Club por la satisfacción del cliente es evidente durante todo el proceso.</p>
						<p>Smiles Club se destaca como la opción clara cuando se trata de empresas de alineadores transparentes, ofreciendo una experiencia superior con el mejor precio y servicio del mercado. A diferencia de otras empresas, Smiles Club prioriza la asequibilidad, garantizando que los precios de sus alineadores transparentes sean competitivos sin comprometer la calidad. Con Smiles Club, puedes lograr una sonrisa segura sin tener que gastar mucho dinero. </p>
						</div>
					`
				}
			],
			"TopMainSectionImage1": [
				{
					"alt": `Mujeres con alineador invisible`
				}
			],
			"Compare": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">COMPARA TÚ MISMO</div>
						<h2 class="sectionTitle2 limitWidth">Alineadores de máxima calidad a una fracción del precio</h2>
						<div class="subHeader" style="max-width: 600px; margin: auto;">Con Smiles Club puedes gestionar tu presupuesto y priorizar tu salud bucal sin comprometer la calidad de la atención. No es necesario gastar una fortuna para conseguir una sonrisa perfecta </div>
					`
				},
				{
					"type": "span",
					"content": `Otros Alineadores`
				},
				{
					"type": "span",
					"content": `Frenillos Tradicionales`
				},
				{
					"content": [
						["Calidad","Alta","Varía","Varía"],
						["Visitas Clínicas","No ","No","Si"],
						["Interes","Ninguno","Bajo","Alto"],
						["Términos de Financiación","Ninguna","0 - 24 meses","0 - 60 meses"],
						["Debt","Ninguna","Baja","Alta"],
						["Recolección","Ninguna","Si","Si"],
						["Ataches","No","No","Si"],
						["Discreto","Si","Si","No"],
						["Costo","Promedio $1,849","Promedio $3,500","Promedio $5,500"],
						["Costo de Retenedores","$75","Promedio $225","Promedio $300"],
						["Hechos en EEUU","Si","Varía","Varía"]
					]
				},
				{
					"content": `Mother and daughter looking at teeth straightening kits online`
				}
			],
			"FullSteps": [
				{
					"type": "div",
					"content": `
						<div class="preHeader">Nos enfocamos en la calidad</div>
						<h2 class="sectionTitle2 limitWidth">Nuestro secreto para el éxito y los precios más bajos es la alta calidad sin cosas innecesarias.</h2>
						<div class="subHeader">Únete a nuestro Club y descubre la diferencia</div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3>Nos preocupamos por tu sonrisa</h3>
						<div class="text">
							<div>Si nuestro Ortodoncista determina que no eres un buen candidato para el tratamiento, te reembolsaremos el costo de tu kit de impresión.</div>
							<div>Si no estás satisfecho con tu tratamiento durante el primer mes, te reembolsaremos el costo.</div>
						</div>
					`
				},
				{
					"type": "div",
					"content": `
						<h3>Te atenderemos durante y después del tratamiento</h3>
						<div class="text">
							<div>Si debe pausar el tratamiento, continúa usando el último alineador hasta que desees reanudarlo y te acomodaremos, sin penalizaciones.</div>
							<div>Después de completar el tratamiento, puedes solicitar nuestros retenedores con descuento para usar por las noche, para que tu nueva sonrisa sea permanente.</div>
						</div>
					`
				}
			],
			"FullStepsButtons": [
				{
					"type": "span",
					"content": `Comencemos`
				},
				{
					"type": "span",
					"content": `Contáctanos`
				},
				{
					"content": `/precios`
				}
			],
			"FullStepsImages": [
				{
					"alt": `Una joven blanca tomando un selfie en su teléfono inteligente.`
				},
				{
					"alt": `Una joven negra tomando un selfie en su teléfono inteligente.`
				}
			]
		}
	}
};


	
export default PAGE;



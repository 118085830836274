import React from 'react';
import Nav from "../Nav/index";
import {NavLink, Link} from 'react-router-dom';
import CartButton from 'website/components/CartButton/index';
import LogoDark from 'global/img/logo-dark.svg';
import LayoutBuilder from 'LayoutBuilder';

const {Container, Row, Column} = LayoutBuilder;

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNav: true
    };
    this.resetNav = this.resetNav.bind(this);
    this.LoginEventKey = window.GlobalUtil.subscribeEvent("LoginEvent", curSession=>{
      this.setState({date: new Date()});
    });
    this.scrollHelper = this.scrollHelper.bind(this);
  }

  componentDidMount(){          
    document.addEventListener("scroll", this.scrollHelper);
    this.scrollHelper();
    var HeaderStyles = {"height": this.HeaderRef.offsetHeight+"px", "minHeight": "92px"};
    this.setState({HeaderStyles: HeaderStyles})
  }

  componentWillUnmount(){
    this.LoginEventKey.unsubscribe();
    document.removeEventListener("scroll", this.scrollHelper);
  }

  scrollHelper(e){
    var {offtop} = this.state;
    //console.log("document.documentElement.scrollTop ","\n\n",document.documentElement.scrollTop,"\n\n");
    const scrollTop = document.documentElement.scrollTop;
    if(scrollTop > 0){
      if(!offtop) this.setState({offtop: true});
    } else {
      if(offtop) this.setState({offtop: false});
    }
  }


  resetNav(){
    this.setState({resetNav: false}, ()=>this.setState({resetNav: true}));
    if(this.props.onClickLink) this.props.onClickLink()
  }

  render(){
    var {showNav, offtop, HeaderStyles={"minHeight": "92px"}} = this.state;
    var CurDate = new Date();           
    var isLoggedIn = window.GlobalUtil.getGlobalVariable("isLoggedIn", false);
    return (
      <div ref={e=>this.HeaderRef=e} id="Header" className={offtop ? "offtop" : ""} style={HeaderStyles}>
        <div className={"headerFloater "+(HeaderStyles.height ? "floatingFixed" : "")}>
          <div className="preHeaderWrapper">
          </div>
          <Container className="headerContainer">
            <Row className="">
              <Column className="image-column" col="" sm="" sm="" md="" lg="" xl="">
                <div className="logo-image">
                  <img src={LogoDark} alt=""/>
                </div>
              </Column>
              <Column className="menu-col d-none d-sm-none d-md-none d-lg-flex" col="" sm="" md="" lg="" xl="">
                <div className="menu-nav d-none d-sm-none d-md-none d-lg-flex">
                  {showNav && <Nav onClickLink={this.resetNav} MenuToShow={window.Session.Language === "ESPAÑOL" ? "EspHeader" :"Header"} inline="true" CleanLinks={this.props.CleanLinks}/>}
                  <CartButton />
                </div>
              </Column>
              <div className="mobile-button d-flex d-sm-flex d-md-flex d-lg-none">
                <CartButton />
                <div className="mobile-button-box">
                  <i className="fas fa-bars" onClick={this.props.onShowSideMenu} />
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}